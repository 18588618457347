import {react,useEffect,useState,useRef, useContext} from "react";
import styled from "styled-components"
import colors from "./colors.js"
import QRimg from "../img/addressQR.png"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import PointStateContext from "../contextAPI/PointState.js";
import axios from "axios";
import { server } from "../hook/constValue.js";
import DepositFunc from "../hook/Deposit.js";
import ReceiptModal from "./ReceiptModal.js";
const PointUP = ({EthPrice, email,alias})=>{
    const [ButtonState,SetButtonState] = useState(false);
    const [Receipt,SetReceipt] = useState(false);
    const PointHandler = useContext(PointStateContext);
    const [SelectedState, SetSelectedState] =useState("point")
    const LevelSelect =()=>{
        if(alias=="mate"){
        return (
            <div>
            <Hr/>
                <br/>
            <FlexDiv2>
            <QRTitle>원하시는 회원 등급을 선택하세요.</QRTitle><br/>
                <InputBox2 type="radio" value="members" onChange={(e)=>{SetSelectedState(e.target.value)}}/><BoxTitle22>멤버스</BoxTitle22>
                <InputBox2 type="radio" value="friend" onChange={(e)=>{SetSelectedState(e.target.value)}}/><BoxTitle22>프랜드</BoxTitle22>
            </FlexDiv2>
            <br/>
            </div>
        )}
        else{
            return <div/>
        }
    }
    const EthHandler = (e)=>{
        let ETHValue = e.target.value;
        if(ETHValue == ""){
            ETHValue=0;
        }
        const Value = parseInt(ETHValue*EthPrice);
        if(Value==1){
            PointHandler.SetPointKRW(0);
        }
        else{
            const Result = Value.toLocaleString('ko-KR', {
                maximumFractionDigits: 4
              })
            PointHandler.SetPointKRW(Result);
            if(Value>50000){
                SetButtonState(true);
            }
            else{
                SetButtonState(false)
            }
        }
        PointHandler.SetPointETH(ETHValue)
        if(ETHValue>0){
            PointHandler.SetPointETH(parseFloat(ETHValue))
        }
    }
    const KrwHandler = (e)=>{
        let KRW = e.target.value;
        let CurrentKRW
        if(KRW==""){
            CurrentKRW = 0;
        }
        else{
            CurrentKRW = parseInt(KRW.replace(",", "").replace(",", "").replace(",", ""));
        }
        if(CurrentKRW<50000){
            PointHandler.SetPointETH("");
            SetButtonState(false)
        }
        else{
            SetButtonState(true)
            const ETHPriceValue = EthPrice;
            const KRWValue =CurrentKRW;
            const Value = parseInt(KRWValue)/ETHPriceValue;
            const CalcedValue = Math.ceil((Value*10000))/10000;
            PointHandler.SetPointETH(CalcedValue);
        }
        const Result = CurrentKRW.toLocaleString('ko-KR', {
            maximumFractionDigits: 4
          });
        
        PointHandler.SetPointKRW(Result)
    }
    const Point_DepositHandler = ()=>{
        let Amount = PointHandler.PointETH;
        let Address= PointHandler.PointAddress;
        
        DepositFunc(email, Address,Amount,SelectedState,true,false).then(()=>{alert("관리자가 확인한 후 24시간 이내에 처리하겠습니다. 일주일 이상 포인트 충전이 안 되는 경우 아래 이메일로 문의해주세요. cada8585@daum.net")});
    }
    return(
        <div>
        <BoxTitle2>입금자 지갑 주소 : </BoxTitle2>
        <InputBox onChange={(e)=>{PointHandler.SetPointAddress(e.target.value)}}/>
        <BoxTitle2>입금양(ETH) : </BoxTitle2>
        <InputBox value={PointHandler.PointETH} onChange={EthHandler} />
        <BoxTitle2>입금양(KRW) : </BoxTitle2>
        <InputBox value={PointHandler.PointKRW} onChange={KrwHandler}/>
        <QRImgDiv>
            <QRTitle>입금 QR</QRTitle>
            <ImgIcon src={QRimg}/>
        </QRImgDiv>
        <LevelSelect/>
        <Hr/>
        <QRInputSpan> 입금할 주소 : 0xca3B83433D3Ae521B3e1b6884bf8f0Ab078131c5
        <CopyToClipboard text="0xca3B83433D3Ae521B3e1b6884bf8f0Ab078131c5">
            <BoxContent4 onClick={()=>{alert("주소가 복사되었습니다.")}}>복사</BoxContent4>
            </CopyToClipboard>
        </QRInputSpan>
        <QRTitle>*** 포인트 충전 입금양은 최소 5만원 이상입니다. ***</QRTitle>
        <QRTitle>포인트환불은 소비자보호법에 준용합니다. <br/>
            단)회비에 포함된 포인트및 무상 제공된 포인트 제외 </QRTitle>
        <ButtonBox State={ButtonState} disabled={!ButtonState} onClick={()=>Point_DepositHandler()}>입금 하기</ButtonBox>
        </div>
    ) 
}
const PointUpKRW=({email,alias})=>{
    const [ButtonState,SetButtonState] = useState(false);
    const [Receipt,SetReceipt] = useState(false);
    const PointHandler = useContext(PointStateContext);
    const [SelectedState, SetSelectedState] =useState("point")
    const [ReceiptModalState,SetReceiptModal] = useState(false)
    const [ReceiptType,SetReceiptType] = useState(null);
    const [ReceiptInfo,SetReceiptInfo] = useState(null);

    const LevelSelect =()=>{
        if(alias=="mate"){
        return (
            <div>
                <Hr/>
                <br/>
                
            <FlexDiv2>
            <QRTitle>원하시는 회원 등급을 선택하세요.</QRTitle><br/>
                <InputBox2 type="radio" value="members" onChange={(e)=>{SetSelectedState(e.target.value)}}/><BoxTitle22>멤버스</BoxTitle22>
                <InputBox2 type="radio" value="friend"  onChange={(e)=>{SetSelectedState(e.target.value)}}/><BoxTitle22>프랜드</BoxTitle22>
            </FlexDiv2>
            <br/>
            </div>
        )}
        else{
            return <div/>
        }
    }

    const Point_DepositHandler = ()=>{
        let Amount = parseInt(PointHandler.PointKRW.replace(",", "").replace(",", "").replace(",", ""));;
        let Address= PointHandler.PointAddress;
        if(Receipt){
        DepositFunc(email, Address,Amount,SelectedState,false,Receipt,ReceiptInfo,ReceiptType).then(()=>{alert("관리자가 확인한 후 24시간 이내에 처리하겠습니다. 일주일 이상 포인트 충전이 안 되는 경우 아래 이메일로 문의해주세요. cada8585@daum.net")});;     
        }
        else{
            DepositFunc(email, Address,Amount,SelectedState,false,Receipt,"null","null").then(()=>{alert("관리자가 확인한 후 24시간 이내에 처리하겠습니다. 일주일 이상 포인트 충전이 안 되는 경우 아래 이메일로 문의해주세요. cada8585@daum.net")});;     

        } 
    }

    const KrwHandler = (e)=>{
        let KRW = e.target.value;
        let CurrentKRW
        if(KRW==""){
            CurrentKRW = 0;
        }
        else{
            CurrentKRW = parseInt(KRW.replace(",", "").replace(",", "").replace(",", ""));
        }
        if(CurrentKRW<50000){
            SetButtonState(false)
        }
        else{
            SetButtonState(true)
        }
        const Result = CurrentKRW.toLocaleString('ko-KR', {
            maximumFractionDigits: 4
          });
        
        PointHandler.SetPointKRW(Result)
    }
        return(
            <RelativeBox>
            <BoxTitle2>입금자 이름 : </BoxTitle2>
            <InputBox onChange={(e)=>{PointHandler.SetPointAddress(e.target.value)}}/>
            <BoxTitle2>입금양(KRW) : </BoxTitle2>
            <InputBox value={PointHandler.PointKRW} onChange={KrwHandler}/>
            <LevelSelect/>
            <Hr/>
            <QRTitle> 입금 계좌 : 351-1200-5819-03 농협 백랑기</QRTitle><br/>
            <QRTitle>*** 포인트 충전 입금양은 최소 5만원 이상입니다. ***</QRTitle>
            <QRTitle>포인트환불은 소비자보호법에 준용합니다. <br/>
            단)회비에 포함된 포인트및 무상 제공된 포인트 제외 </QRTitle>
            <FlexDiv>
            <InputCheckBox checked={Receipt} onChange={()=>{SetReceipt(!Receipt);if(!Receipt){SetReceiptModal(true)}}} type="checkbox"/><QRTitle>현금영수증 신청</QRTitle>
            </FlexDiv>                      
            <ButtonBox State={ButtonState} disabled={!ButtonState} onClick={()=>Point_DepositHandler()}>입금 하기</ButtonBox>
            {ReceiptModalState?<ReceiptModal SetModalState={SetReceiptModal} SetReceiptInfo={SetReceiptInfo} SetReceiptType={SetReceiptType}/>:null}

            </RelativeBox>
        )     
}
export const Point = ({HowDeposit, email, alias})=>{
    const [EthPrice, setETHprice] = useState();
    useEffect(async ()=>{
        const Price = await axios.post(server+"/getEthPrice").then((res)=>{return res})
        setETHprice(await Price.data);
    },[])
    if(HowDeposit){
        return <PointUP email={email} EthPrice={EthPrice} alias={alias}/>
    }
    else{
        return <PointUpKRW email={email} alias={alias}/>
    }
}
const RelativeBox = styled.div`
    position : relative;
`

const FlexDiv = styled.div`
    display : flex-box;
    flex-direction : row;
    flex-wrap: nowrap;
    align-items : center;
    justify-content : center;
`
const FlexDiv2 = styled.div`
    text-align : center;
`
const InputCheckBox = styled.input`
    height : 15px;
    border : 1px solid black;
    width : 15px;
    position : relative;
    top : 5px;
    vertical-align : top;
    @media screen and (max-width: 768px) 
    {
        top : 3px;
    }
`
const InputBox= styled.input`
    width :calc(100% - 220px);
    height : 30px;
    padding : 5px 15px;
    vertical-align : middle;
    border : none;
    margin : 10px ;
    font-family : Dream4;
    border-bottom : 1px solid black;
    border-radius : 20px;
    @media screen and (max-width: 768px) 
{ & { 
    min-width :0;  
    width : 100%;
    box-sizing : border-box;
    text-align : left;
    margin : 20px 0;
    }
}
`
const InputBox2= styled.input`
    width :30px
    height : 30px;
    padding : 5px 15px;
    vertical-align : middle;
    border : none;
    margin : 10px ;
    font-family : Dream4;
    border-bottom : 1px solid black;
    border-radius : 20px;
    @media screen and (max-width: 768px) 
{ & { 
    min-width :0;  
    width : 15;
    box-sizing : border-box;
    text-align : left;
    margin : 20px 0;
    }
}
`
const QRImgDiv = styled.div`
    width : 100%;
    text-align : left;
    @media screen and (max-width: 768px) 
{ & { 
    min-width :0;  
    width : 100%;
    box-sizing : border-box;
    margin : 20px 0;
    }
}
`
const ButtonBox = styled.button`
    height : 60px; 
    width : 240px;
    cursor:pointer;
    border-radius : 40px;
    background-color : ${props=>{
        if(props.State){
            return colors.SkyBlue
        }
        else{
            return "gray"
        }
    }};
    font-family : Dream5;
    font-size : 24px;
    border : none;
    text-align : center;
    color : white;
    display : block;
    margin :20px auto;
    box-shadow: 3px 2px 5px rgba(0, 0, 0,0.2);
    `
const ImgIcon = styled.img`
    width : 150px;
    height : 150px;
    display : inline-block;
    margin : 20px 0 ;
    position : relative;
    left : calc(100% - 220px);
    @media screen and (max-width: 768px) 
    { & { 
        margin : 20px auto;
        margin-top : 50px;
        left : calc(100% - 210px);
            }
    }
`
const QRTitle = styled.span`
    display : inline-block;
    color : ${colors.White};
    font-size : 15px;
    font-family : Dream5;
    margin : 5px 0;
    vertical-align : top;
    word-wrap: break-word;
    width : 90%;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 90%;
        word-wrap: break-word;
    }
    }
`
const QRInputSpan = styled.span`
    display : inline-block;
    color : ${colors.White};
    font-size : 15px;
    font-family : Dream5;
    margin : 15px 0;
    vertical-align : top;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 100%;
        word-wrap: break-word;
    }
    }
`
const BoxTitle2 = styled.span`
    display : inline-block;
    color : ${colors.White};
    font-size : 15px;
    font-family : Dream5;
    margin : 15px 0;
    width : 160px;
    vertical-align : middle;
    text-align : left;
    @media screen and (max-width: 768px) 
{ & { 
    font-size : 15px;
    display : inline-block;
    padding : 0; 
    margin : 0;

    }
}
`
const BoxTitle22 = styled.span`
    display : inline-block;
    color : ${colors.White};
    font-size : 15px;
    font-family : Dream5;
    margin : 15px 0;
    margin-right : 20px;
    vertical-align : middle;
    text-align : left;
    @media screen and (max-width: 768px) 
{ & { 
    font-size : 15px;
    display : inline-block;
    padding : 0; 
    margin : 0;
    width : 100px;
    }
}
`
const BoxContent4 = styled.span`
display : inline-block;
color : ${colors.White};
font-size : 15px;
font-family : Dream5;
margin : 0px 3px;
top : -3px;
position : relative;
padding : 3px;
vertical-align : middle;
border : 1px solid white;
@media screen and (max-width: 768px) 
{ & { 
    font-size : 15px;
    display : inline-block; 
    margin : 3px ;
    box-sizing : border-box;
    text-align : left;
    }
}
`
const Hr = styled.div`
    margin : 10px 0;
    widht : 90%;
    border-top: 1px solid ${colors.White};
`
export default Point;