import {react,useEffect,useState,useRef,useContext} from "react";
import styled from "styled-components"
import back from "../img/backimg.png";
import GetPost from "../hook/GetPost";
import Header from "./Header";
import colors from "./colors";
import { useHistory } from "react-router";
import Footer from "./Footer"
import icon_call from "../img/icon_call.png"
import icon_email from "../img/icon_email.png";
const Manual1=()=>{
    return(
        <div>
        <BodyImgWrapper>
        <Header/>
        </BodyImgWrapper>
        <BodyWrapper>
                <Title>개인정보 처리 방침</Title>
                <Content>코인엔젤은 (이하 '회사'는) 코인엔젤 서비스 (이하 ‘서비스’)를 제공하기 위해서 고객님의 개인정보를 수집하고 있으며 고객님의 개인정보를 중요시하며, “정보통신망 이용촉진 및 정보보호”에 관한 법률을 준수하고 있습니다.<br/>
회사는 개인정보처리방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.<br/>
회사는 개인정보처리방침을 개정하는 경우 서비스 공지사항(또는 개별공지)을 통하여 공지할 것입니다.<br/>
</Content>
                <ContentWrapper>
                <SpanDiv>
                    <Title>■ 수집하는 개인정보 항목</Title>
                    <SpanNum>회사 서비스 제공을 위한 필요 최소한의 개인정보를 수집하고 있습니다. <br/>
회원 가입 시 또는 서비스 이용 과정에서 애플리케이션을 통해 아래와 같은 서비스 제공을 위한 필요 최소한의 개인정보를 수집하고 있습니다. <br/>
<br/>
[회원 가입시]<br/>
ο 필수 수집항목 : 이메일, 패스워드, 닉네임, 이름, 성별, 생일 <br/>
<br/>
[서비스 이용시]<br/>
ο 수집항목 : 가상화폐 거래소 API Key 및 Secret Key<br/>
<br/>
[환불 처리시]<br/>
ο 수집항목 : 환불처리 받을 가상화폐 지갑 주소<br/>
<br/>
[자동 생성 항목]<br/>
서비스 이용기록 , 접속 로그 , 접속 IP 정보 , 결제기록<br/>
<br/>
회사는 아래의 방법을 통해 개인정보를 수집합니다. <br/>
- 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대한 동의를 하고 직접 정보를 입력하여 수집될 수 있습니다. <br/>
- 고객센터를 통한 상담과정에서 이메일, 팩스, 전화 등을 통해 고객님의 개인정보가 수집될 수 있습니다. <br/>
- 기기정보와 같은 생성정보는 PC웹, 모바일 웹/앱 이용 과정에서 자동으로 생성되어 수집될 수 있습니다.<br/> </SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>■ 개인정보의 수집 및 이용목적</Title>
                    <SpanNum> 회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br/>

ο 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산을 위해서만 개인정보를 이용합니다. <br/>
</SpanNum>
                </SpanDiv>
                </ContentWrapper>

                <ContentWrapper>
                <SpanDiv>
                    <Title>■ 개인정보의 보유 및 이용기간
</Title>
                    <SpanNum> 원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다. 다음의 목적 이외에 다른 목적으로 절대 사용하지 않습니다. <br/>
                    <br/>
ο 회사 내부 방침에 의한 정보 보유<br/>
 - 보존 이유 : 부정거래 방지 및 서비스 운영방침에 따른 보관 <br/>
 - 보존 기간 : 5년<br/>

ο 관련 법령에 의한 정보 보유<br/>
가. 계약 또는 청약철회 등에 관한 기록<br/>
- 보존이유 : 전자상거래등에서의소비자보호에관한법률<br/>
- 보존기간 : 5년<br/>

나. 대금 결제 및 재화 등의 공급에 관한 기록<br/>
- 보존이유 : 전자상거래등에서의 소비자보호에관한법률<br/>
- 보존기간 : 5년<br/>

다. 소비자 불만 또는 분쟁처리에 관한 기록<br/>
- 보존이유 : 전자상거래등에서의 소비자보호에관한법률<br/>
- 보존기간 : 3년<br/>

라. 로그 기록<br/>
- 보존이유: 통신비밀보호법<br/>
- 보존기간 : 3개월<br/>

</SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>■ 개인정보의 파기절차 및 방법
</Title>
                    <SpanNum> 
회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.<br/>

ο 파기절차<br/>
회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기 되어집니다.
별도 DB로 옮겨진 개인정보는 정한 목적 이외 다른 목적으로 이용되지 않습니다.<br/>
<br/>
ο 파기방법<br/>
- 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br/>
<br/>
</SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>■ 개인정보 제공
</Title>
                    <SpanNum> 
                    회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.<br/>
                    <br/>
- 이용자들이 사전에 동의한 경우<br/>
- 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br/>
</SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>■ 수집한 개인정보의 위탁
</Title>
                    <SpanNum> 
                    회사는 편리하고 더 나은 서비슬 제공하기 위하여 업무 중 일부를 외부에 위탁하고 있습니다. <br/>
수탁업체 : KT ucloud <br/>
위탁업무 내용 : 서비스 제공을 위한 서버 및 네트워크 운영<br/>
위탁 개인정보의 보유 및 이용기간 : 회원 탈퇴 시 혹은 계약 종료시 까지<br/>
</SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>■ 이용자 및 법정대리인의 권리와 그 행사방법

</Title>
                    <SpanNum> 
                    이용자 및 법정 대리인은 언제든지 등록 되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 ‘개인정보변경’(또는 ‘회원 정보수정’ 등)을 가입해지(동의철회)를 위해서는 “회원탈퇴”를 클릭 하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.
귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자 에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.
코인엔젤은 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 “코인엔젤 이 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>■ 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항
</Title>
                    <SpanNum> 
                    
회사는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운용합니다. <br/>쿠키란 서비스의 웹사이트를 운영하는데 이용되는 서버가 귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다. 코인엔젤은 다음과 같은 목적을 위해 쿠키를 사용합니다.
<br/>
<br/>
▶ 쿠키 등 사용 목적<br/>
<br/>
- 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공<br/>
귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 귀하는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
<br/>
▶ 쿠키 설정 거부 방법<br/>
예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.<br/>
설정방법 예(인터넷 익스플로어의 경우)<br/>
: 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보<br/>
단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.<br/>


</SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>■ 개인정보에 관한 민원서비스
</Title>
                    <SpanNum> 
                    회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.<br/>
                    <br/>
개인정보관리책임자 성명 : 백랑기<br/>
이메일 : cada8585@daum.net<br/>
<br/>
귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
<br/>
※ 동의를 거부할 수 있으나 거부 시 사이트 회원 서비스 이용이 불가능합니다.
<br/>
시행 2021.9.24. </SpanNum>
                </SpanDiv>
                </ContentWrapper>
        </BodyWrapper>
        <Footer/>
        </div>
    );

}
const Title = styled.span`
    display : block;
    font-size : 30px;
    font-family : Dream5;
    padding : 20px 0;
    width : 100%;
    @media screen and (max-width: 768px) 
{ & { 
    min-width : 0;
    padding :10px 0;
    }
`
const Content = styled.span`
display : block;
font-size : 20px;
font-family : Dream3;
margin : 2% auto;
padding : 20px 0;
text-align : left;
padding-bottom : 100px;
color : ${colors.textGray};
border-bottom : 2px solid ${colors.backBlue};
@media screen and (max-width: 768px) 
{ & { 
    margin-bottom : 80px;    
}
`

const Icon = styled.img`
    width : 40px; 
    height : 40px; 
    margin-right : 40px;
`
const SpanDiv = styled.div`
    display : inline-block;
    
`
const SpanNum = styled.span`
    font-size : 20px;
    color : ${colors.textGray};
    text-align : left;
    line-height : 33px;
`
const ContentWrapper = styled.div`

    width : 90%;
    justify-content : center;
    align-items : center;
    margin : 30px; 
    text-align : left;
    @media screen and (max-width: 768px) 
    { & { 
        width : 84%;
        margin : 20px auto;
        justify-content : center;

        }
    }
`

const BodyWrapper = styled.div`
    padding : 60px 150px;
    display : block;
    width : calc(100% - 300px);
    min-width :1215px;  
    text-align : left;
    background-color : ${colors.background};
    text-align : center;
    @media screen and (max-width: 768px) 
{ & { 
    min-width : 0;
    padding : 70px 20px;
    width : calc(100% - 40px);
    }
}
`
const BodyImgWrapper = styled.div`
    position : relative; 
    width : 100%;
    height : 130px;
    background-image : url(${back});
    background-size : 100% auto;
    background-position-y :300px;
    @media screen and (max-width: 768px) 
{ & { 
    background-position-y :0px;
    }
`

export default Manual1;