import {react,useEffect,useState,useRef,useContext} from "react";
import styled from "styled-components"
import back from "../img/backimg.png";
import GetPost from "../hook/GetPost";
import Header from "./Header";
import colors from "./colors";
import { useHistory } from "react-router";
import Footer from "./Footer"
import icon_call from "../img/icon_call.png"
import icon_email from "../img/icon_email.png";
const AngelCenter=()=>{
    return(
        <div>
        <BodyImgWrapper>
        <Header/>
        </BodyImgWrapper>
        <BodyWrapper>
                <Title>안내페이지</Title>
                <Content>궁금한 사항은 연락주시면 친절히 답변해 드리겠습니다.</Content>
                <ContentWrapper>
                <Icon src={icon_call}/>
                <SpanDiv>
                    <Title>고객센터 번호</Title>
                    <SpanNum> 031-312-8585</SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <Icon src={icon_email}/>
                <SpanDiv>
                    <Title>이메일 문의</Title>
                    <SpanNum> cada8585@daum.net</SpanNum>
                </SpanDiv>
                </ContentWrapper>
        </BodyWrapper>
        <Footer/>
        </div>
    );

}
const Title = styled.span`
    display : block;
    font-size : 30px;
    font-family : Dream5;
    padding : 20px 0;
    width : 100%;
    @media screen and (max-width: 768px) 
{ & { 
    min-width : 0;
    padding :10px 0;
    }
`
const Content = styled.span`
display : block;
font-size : 20px;
font-family : Dream3;
margin : 2% auto;
padding : 20px 0;
padding-bottom : 100px;
color : ${colors.textGray};
border-bottom : 2px solid ${colors.backBlue};
@media screen and (max-width: 768px) 
{ & { 
    margin-bottom : 80px;    
}
`

const Icon = styled.img`
    width : 40px; 
    height : 40px; 
    margin-right : 40px;
`
const SpanDiv = styled.div`
    display : inline-block;
    
`
const SpanNum = styled.span`
    font-size : 20px;
    color : ${colors.textGray};
    @media screen and (max-width: 768px) 
{ & { 
    text-align : left;   
}
`
const ContentWrapper = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;
    margin : 30px; 
    @media screen and (max-width: 768px) 
    { & { 
        width : 84%;
        margin : 20px auto;
        justify-content : center;
        text-align : left;
        }
    }
`

const BodyWrapper = styled.div`
    padding : 60px 150px;
    display : block;
    width : calc(100% - 300px);
    min-width :1215px;  
    text-align : left;
    background-color : ${colors.background};
    text-align : center;
    @media screen and (max-width: 768px) 
{ & { 
    min-width : 0;
    padding : 70px 20px;
    width : calc(100% - 40px);
    }
}
`
const BodyImgWrapper = styled.div`
    position : relative; 
    width : 100%;
    height : 130px;
    background-image : url(${back});
    background-size : 100% auto;
    background-position-y :300px;
    @media screen and (max-width: 768px) 
{ & { 
    background-position-y :0px;
    }
`

export default AngelCenter;