import {react,useEffect,useState,useRef, useContext} from "react";
import styled from "styled-components"
import colors from "./colors.js"
import backCoin from "../img/coinBack.png";
import Header from "./Header";
import LoginFunc from "../hook/Login.js";
import { useHistory } from "react-router";
import Logo from "../img/LogoCircleBlack.png";
import { LoginInfo } from "../contextAPI/LoginInfo.js";
const Login=()=>{
    const Ref_ID = useRef();
    const Ref_PW = useRef();
    const History = useHistory();
    const UserInfo = sessionStorage.getItem("LoginInfo");
    useEffect(()=>{
        if(UserInfo!=null){
            alert("이미 로그인 하셨습니다.")
            History.push("/MyPage")
        }
    },[])
    return(
        <BodyWrapper>
            <Header/>
            <BodyImgWrapper>
            <BigCircle/>
            <MiddleCircle/>
            <Box>
                <BoxSpanWhite>
                    login
                </BoxSpanWhite>
                <ContentWrapper>
                <BoxSpanOrenge>
                    Email
                </BoxSpanOrenge>
                <InputBox ref={Ref_ID}/>
                <br/>
                <BoxSpanOrenge>
                    Password
                </BoxSpanOrenge>
                <InputBox type="password" ref={Ref_PW}/>
                <ButtonBox onClick={()=>{
                    LoginFunc(Ref_ID.current.value,Ref_PW.current.value,History)}}>login</ButtonBox>
                            <MobileImg2 src={backCoin}/>
                </ContentWrapper>
            </Box>
            </BodyImgWrapper>
        </BodyWrapper>
    );

}
const ButtonBox = styled.button`
cursor:pointer;
    width : 100px;
    height : 50px; 
    border-radius : 30px;
    background-color : ${colors.SkyBlue};
    font-family : Dream5;
    font-size : 24px;
    border : none;
    text-align : center;
    color : white;
    display : inline-block;
    margin-top : 20px;
    box-shadow: 3px 2px 5px rgba(0, 0, 0,0.2);
    @media screen and (max-width: 768px) 
    { & { 
        width : 40%;
        padding : 5px 10px;
        height : 100%;
        font-size : 20px;

        box-sizing : border-box;
        }
    }
`

const InputBox= styled.input`
    width :400px;
    height : 30px;
    padding : 10px 15px;
    vertical-align : middle;
    border-radius : 30px;
    border : none;
    margin : 10px 0 ;
    font-family : Dream4;
    @media screen and (max-width: 768px) 
    { & { 
        width : 60%;
        height : 100%;
        box-sizing : border-box;
        }
    }
`
const BodyWrapper = styled.div`
    min-width :1215px;  
    padding : 0 150px;
    display : block;
    min-height : 100vh;
    width : calc(100% - 300px);
    text-align : left;
    overflow : hidden;
    & > div:first-child{
        width : calc(100% - 300px);
        padding : 25px 0;
        @media screen and (max-width: 768px) 
        { 
            width : 100%;
        }   
        
    }
    & > div:first-child div{
        right : 0;
    }
    & div ul li {
        color : ${colors.textGray};
        @media screen and (max-width: 768px) 
        { 
            color : ${colors.White};
        } 
    } 
    & div ul:first-child li {
        color : ${colors.textGray};
    } 
    & div ul:first-child div {
        background-image : url(${Logo});
    } 
    @media screen and (max-width: 768px) 
    { & { 
        padding : 0 ;
        min-width :0;  
        width : 100%;
        box-sizing : border-box;
        }
    }
`
const BodyImgWrapper = styled.div`
    position : relative; 
    width : 100%;
    height : 100%;
`
const MobileImg2 = styled.img`
    display : block;
    width : 350px;
    position : absolute; 
    top : 300px;
    right : 0;
    @media screen and (max-width: 768px) 
    { & { 
        width : 0;
        }
    }
`
const ContentWrapper = styled.div`
    display : block;
    text-align : center;
`
const BigCircle = styled.div`
    position : absolute;
    background-color : ${colors.backgroundBlue};
    width : 1300px;
    height : 1300px;
    border-radius : 100%;
    z-index : -1;
    top : -1000px;
    left : -400px;
    @media screen and (max-width: 768px) 
    { & { 
        top : -300px;
        left : -100px;
        width : 350px;
        height : 350px;
        }
    }
`
const MiddleCircle = styled.div`
    position : absolute;
    background-color : ${colors.backgroundPuple};
    width : 28vw;
    height : 28vw;
    border-radius : 100%;
    z-index : -1;
    bottom : -15vw;
    right : -180px;
    @media screen and (max-width: 768px) 
    { & { 
        bottom : -300px;
        right : -100px;
        width : 250px;
        height : 250px;
        }
    }
`

const Box = styled.div`
    background-color : rgba(49,49,49,0.8);
    width : 850px;
    border-radius : 20px;
    margin : 220px auto;
    padding : 50px 100px;
    box-sizing : border-box;
    position : relative;
    @media screen and (max-width: 768px) 
    { & { 
        padding : 40px;
        width : calc(100% - 80px);
        box-sizing : border-box;
        margin : 220px 40px;
        }
    }
`
const BoxSpanWhite = styled.span`
font-size : 35px;
color : ${colors.White};
font-family : Dream6;
display : inline-block;
padding-bottom : 30px;
@media screen and (max-width: 768px) 
{ & { 
    width : 100%;
    height : 100%;
    text-align : center;
    font-size : 25px;

}
}
`
const BoxSpanOrenge = styled.span`
display : inline-block;
width : 220px;
font-size : 30px;
color : ${colors.textOrenge};
font-family : Dream6;
vertical-align : middle;
text-align : left;
@media screen and (max-width: 768px) 
{ & { 
    width : 39%;
    font-size : 18px;
    text-align : left;
}
}
`
export default Login;