import {react,useEffect,useState,useRef} from "react";
import styled from "styled-components"
import colors from "./colors.js"
const Modal3 = ({SetModalState})=>{
    return (
        <Box>
            <BoxSpanWhite>
                회원가입은 App에서 진행하실 수 있습니다.
            </BoxSpanWhite>
            <ButtonBox onClick={()=>{SetModalState(false)}}>확인</ButtonBox>
        </Box>
    )

}
const ButtonBox = styled.button`
    height : 50px; 
    width : 270px;
    border-radius : 10px;
    background-color : ${colors.SkyBlue};
    font-family : Dream5;
    font-size : 24px;
    border : none;
    text-align : center;
    color : white;
    display : block;
    margin :10px auto;
    cursor:pointer;
    `
const Box = styled.div`
    background-color : rgba(49,49,49,0.8);
    width : 700px;
    height : 270px;
    border-radius : 20px;
    margin : 0 0;
    padding : 50px 100px;
    box-sizing : border-box;
    position : absolute;
    left : calc(50% - 350px);
    z-index : 10;
    @media screen and (max-width: 768px) 
    { & { 
        width : 80%;
        top : 90%;
        padding : 50px ;
        left : 10vw;
    }
    }
`
const BoxSpanWhite = styled.span`
font-size : 25px;
color : ${colors.White};
font-family : Dream6;
display : inline-block;
padding-bottom : 30px;
@media screen and (max-width: 768px) 
{ & { 
    font-size : 15px;
}
}
`
const BoxSpanOrenge = styled.span`
font-size : 30px;
color : ${colors.textOrenge};
font-family : Dream6;

`
export default Modal3;