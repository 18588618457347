import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch ,withRouter} from 'react-router-dom'
import styled from 'styled-components';
import Header from "./Views/Header.js";
import Body1 from './Views/Body1';
import Body2 from './Views/Body2';
import Body3 from './Views/Body3';
import Body4 from './Views/Body4';
import Footer from './Views/Footer';
import Login from './Views/Login';
import MyPage from './Views/MyPage';
import Manual1 from './Views/Manual1';
import Manual2 from './Views/Manual2';
import Join from './Views/Join';
import { useContext } from 'react';
import LoginInfoContext from './contextAPI/LoginInfo';
import AngelCenter from './Views/AngelCenter';
import AngelCenterPost from './Views/AngelCenterPost';
import Body5 from './Views/Body5';
import { PointStateProvider } from './contextAPI/PointState';
const MainPage=()=>{
  return(
    <div>
      <HeaderWrapper>
      <Header/>
      </HeaderWrapper>
    <Body2/>
    <Body5/>
    <Body1/>
    <Body4/>
    <Footer/>

    </div>
  )
}
const HeaderWrapper =styled.div`
  position : absolute;
  width : 100%;
  z-index : 100;
`
function App() {
  return (
    <div className="App">
    <Router>
    <LoginInfoContext>
      <Route exact path="/">
        <MainPage/>
      </Route>
      <Route exact path="/Login">
      <Login/>
      </Route>
      <Route exact path="/MyPage">
      <PointStateProvider>
      <MyPage/>
      </PointStateProvider>
      </Route>  
      <Route exact path="/Help">
      <AngelCenter/>
      </Route>
      <Route exact path="/Join">
      <Join/>
      </Route>
      <Route path="/Manual1" component={Manual1}/>
      <Route path="/Manual2" component={Manual2}/>
      </LoginInfoContext>
  </Router>
    </div>

  );
}

export default App;
