import axios from "axios";
import {server,getMyInfo} from "./constValue";
const GetMyInfoFunc = (email)=>{
    var postData ={
        "email": email,
      };
    return axios.post(server+getMyInfo,postData).then((res)=>{
        return res.data;
    });      
}
export default GetMyInfoFunc;