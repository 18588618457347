export const server = 'https://coinangel.co.kr:1004';
export const getMyInfo = '/getMyInfo';
export const Login = "/login";
export const Deposit = "/requestDeposit";
export const CheckEmail = "/checkEmail";
export const CheckEmailCode = "/checkCheckcode"
export const CheckNickname = "/checkNickname"
export const Join = "/signup"
export const ChangeLevel = "/change_grade"
export const ChangeNickName = "/changeNickname"
export const ChangePhone = "/changePhone"
export const ChangePw = "/changePw"