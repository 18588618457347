import axios from "axios";
import {server,getMyInfo} from "./constValue";
const GetPost = async ()=>{
    const Data = await axios.post(server+"/notification")
/*    const exampleData =[]

    let DataEx={
      contents: "코인천사(Coin Angel) 서비스 시작",
      notice_id: 14,
      time: 1631857855000,
      title: "코인천사 서비스 시작",
      tradeLog_LIST: null};
      for(let i =0; i<40;i++){
        DataEx.notice_id=i;
        exampleData.push(DataEx);
      }
      console.log(exampleData)
*/    return Data.data
}
export default GetPost;