import {react,useEffect,useState,useRef, useContext} from "react";
import { useHistory } from "react-router";
import styled from "styled-components"
import { LoginInfo } from "../contextAPI/LoginInfo";
import Logo from "../img/LogoCircle.png";
import colors from "./colors.js"
import Modal2 from "./Modal2";
import HamOpen from "../img/Ham.png";
import HamClose from "../img/HamClose.png";

const HamButton = styled.button`
    width : 40px;
    height : 40px; 
    border-radius : 100%;
    border : none;
    z-index : 100;
    margin : 10px 0 ;
    margin-left : 20px;
    background-image : url(${props=>{return props.HamState?HamClose:HamOpen}});
    background-size : 100%;
    background-color : transparent;
    position : relative;
    top : 15px;
`
const Header=()=>{
    const History = useHistory();
    const InfoUser = sessionStorage.getItem("LoginInfo");
    const [Ham,SetHam] =useState(false);
    const [ModalState, SetModalState] = useState(false);

    const [LoginState, SetLoginState] =useState(false);
    useEffect(()=>{
        if(InfoUser==null){
            SetLoginState(false);
        }
        else{
            SetLoginState(true);
        }
    });
    const ButtonHandler = ()=>{
        var bookmarkURL = window.location.href; 
        var bookmarkTitle = document.title; 
        var triggerDefault = false; 
            try{
                window.external.AddFavorite(bookmarkURL,bookmarkTitle);
            }
            catch{
            if (window.external && ('AddFavorite' in window.external)) 
             { // IE Favorite 
                window.external.AddFavorite(bookmarkURL, bookmarkTitle); 
            }else { 
                // WebKit - Safari/Chrome 
                alert((navigator.userAgent.toLowerCase().indexOf('mac') != -1 ? 'Cmd' : 'Ctrl') + '+D 키를 눌러 즐겨찾기에 등록하실 수 있습니다.'); 
            } return triggerDefault;
        }
    }
    const ManuBar = (Ham)=>{
        console.log(Ham.Ham);
        if(Ham.Ham==undefined){
            return(
                <UL2>
                <LI onClick={()=>{History.push("/")}}>
                프로그램 소개
                </LI>
                <LI onClick={()=>{window.location.href="https://coinangel.co.kr:1004/manual"}}>
                    거래소 API 연결 및 프로그램 사용법
                </LI>
                <LI onClick={()=>{History.push("/Help")}}>
                    고객센터
                </LI>
                {LoginState?<ButtonWrapper><ButtonBox onClick={()=>{History.push("MyPage")}}><ButtonBoxSpan>MyPage</ButtonBoxSpan></ButtonBox></ButtonWrapper>:
                    <ButtonWrapper><ButtonBox onClick={()=>{History.push("Login");}}><ButtonBoxSpan>Login</ButtonBoxSpan></ButtonBox>
                    <ButtonBox onClick={()=>{History.push("/Join")}}><ButtonBoxSpan>회원가입</ButtonBoxSpan></ButtonBox>
                    <ButtonBox onClick={()=>{ButtonHandler()}}><ButtonBoxSpan>즐겨찾기</ButtonBoxSpan></ButtonBox></ButtonWrapper>
                    }
                    
                </UL2>
            )    
        }
        else if(Ham.Ham === true){
            return(
                <UL2>
                <LI onClick={()=>{History.push("/")}}>
                프로그램 소개
                </LI>
                <LI onClick={()=>{window.location.href="https://coinangel.co.kr:1004/manual"}}>
                    거래소 API 연결 및 <br/>프로그램 사용법
                </LI>
                <LI onClick={()=>{History.push("/Help")}}>
                    고객센터
                </LI>
                {LoginState?<ButtonWrapper><ButtonBox onClick={()=>{History.push("MyPage")}}><ButtonBoxSpan>MyPage</ButtonBoxSpan></ButtonBox></ButtonWrapper>:
                    <ButtonWrapper><ButtonBox onClick={()=>{History.push("Login");}}><ButtonBoxSpan>Login</ButtonBoxSpan></ButtonBox>
                    <ButtonBox onClick={()=>{History.push("/Join")}}><ButtonBoxSpan>회원가입</ButtonBoxSpan></ButtonBox>
                    <ButtonBox onClick={()=>{ButtonHandler()}}><ButtonBoxSpan>즐겨찾기</ButtonBoxSpan></ButtonBox></ButtonWrapper>}
                    
                </UL2>
            )
        }
        else{
            return(<div/>)
        }
    }
    return (
        <HeaderWrapper>
            <UL>
            <LogoImg onClick={()=>{History.push("/")}}/>
            <LI_logo>Coin Angel</LI_logo>
            {window.innerWidth<700?<HamButton HamState={Ham} onClick={()=>{SetHam(!Ham)}}></HamButton>:<div/>}
            </UL>
            {window.innerWidth<700?<ManuBar Ham={Ham}/>:<ManuBar />}
            {ModalState?<Modal2 SetModalState={SetModalState}/>:<div/>}

        </HeaderWrapper>
    )

}
const ButtonWrapper = styled.div`
position :absolute;
top : 25px;
height : 84px;
right : 135px;
@media screen and (max-width: 768px) 
{ & { 
    width : 160px;
    height : fit-content;
    position : relative;
    margin : 0 auto;
    right : 0;
}
}
`
const ButtonBoxSpan = styled.span`
text-align : center;
width : 100%;
display : inline-block;
color : ${colors.White};
`
const ButtonBox = styled.a`
    cursor: pointer;
    display : inline-block;
    width : 70px;
    border-radius : 10px;
    background-color : ${colors.SkyBlue};
    box-shadow : 3px 2px 5px ${colors.Black};
    font-family : Dream5;
    font-size : 15px;
    margin : 18px 5px;
    cursor:pointer;
    padding : 10px 5px;
    z-index : 10;
    @media screen and (max-width: 768px) 
    { & { 
        display : block;
        font-size : 25px;
        width : 150px;
        margin : 15px 0;
        }
    }
`

const HeaderWrapper = styled.div`
position : absolute;
text-align : left ;
padding : 25px 150px;
min-width :1215px;  
z-index : 10;
box-sizing :border-box;
width : 100%;
@media screen and (max-width: 768px) 
{ & { 
    padding : 20px 40px;
    min-width :0;  
    width : 100%;
    box-sizing : border-box;
    text-align : center;
    z-index : 100;
    }
}
`
const LogoImg = styled.div`
display : inline-block;
    width : 80px;
    height : 80px;
    margin-right :25px;
    background-image : url(${Logo});
    background-size : 100%;
    @media screen and (max-width: 768px) 
    { & { 
        width : 70px;
        height : 70px;
        margin-right :15px;
        vertical-align : middle;
        }
    }    
`
const UL = styled.ul`
list-style : none;
margin : 0px;
margin-right : 15px;
padding : 0;
display : inline-block;
vertical-align : middle;
@media screen and (max-width: 768px) 
{ & { 

    }
}    
`
const UL2 = styled.ul`
width : calc(100% - 580px);
list-style : none;
margin : 0px;
text-align : center;
padding : 0;
display : inline-block;
vertical-align : middle;
@media screen and (max-width: 768px) 
{ & { 
    width : 100%;
    height : 120vh;
    background-color : rgba(0,59,119,0.7);
    position : absolute;
    left : 0;
    top : -20px;
    margin : 0;
    padding : 150px 0 ;
}
}    
`
const LI_logo = styled.li`
font-size : 52px;
color : ${colors.White};
height : 80px; 
display : inline-block;
vertical-align : top; 
line-height : 100px;
font-family : designBold;
@media screen and (max-width: 768px) 
{ & { 
    font-size : 32px;
    height : 35px; 
    line-height : 50px;
    vertical-align : middle;
    width : 150px;
    }
}    
`
const LI = styled.li`
font-size : 20px;
color : ${colors.White};
display : inline-block;
font-family : Dream5;
padding : 0 10px;
padding-bottom : 8px;
cursor: pointer;
@media screen and (max-width: 768px) 
{ & { 
    font-size : 30px;
    height : fit-content; 
    line-height : 50px;
    vertical-align : middle;
    display : block;
    margin : 20px 0;
    z-index : 100;
    }
}    
`
export default Header;