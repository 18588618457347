import {react,useEffect,useState,useRef,useContext} from "react";
import styled from "styled-components"
import back from "../img/backimg.png";
import colors from "./colors.js"
import backMobile from "../img/Mobile1.png";
import GetPost from "../hook/GetPost";
import Arrow from "../img/Arrow.png"
const Body1=()=>{
    console.log(GetPost());
    return(
        <BodyWrapper>
            <BodyImgWrapper>
            <ContentWrapper>
            <ContentText3>매수 매도 타이밍을 항상 놓치고 </ContentText3><br/>
            <Image/>
            <ContentText3>직장 일하랴 쳐다볼 시간도 없고</ContentText3>
            <Image/>
            <ContentText3>잠자는 동안에도 알아서 척척 해주면 얼마나 좋을까?</ContentText3>
            </ContentWrapper>
            <MobileImg src={backMobile}/> 
            </BodyImgWrapper>
        </BodyWrapper>
    );

}
//결제는 웹에서 회원가입은 앱에서 하라고 안내하기. 
const Image = styled.div`
background-image : url(${Arrow});
background-size : 100%;
background-repeat: no-repeat;
width : 50px;
height : 60px;
margin : 10px auto;
@media screen and (max-width: 768px) 
    { & { 
        width : 20px;
        height : 23px;
        margin : 3px auto;
        }
    }
`
const BodyWrapper = styled.div`
    padding : 0 150px;
    display : block;
    width : calc(100% - 300px);
    min-width :1215px;  
    height: 32vw;
    background-image : url(${back});
    background-size : 100%;
    text-align : left;
    @media screen and (max-width: 768px) 
    { & { 
        padding : 40px 40px;
        min-width :0;  
        width : 100%;
        height: 100%;
        background-size : auto 100%;
        background-position-x : -120px;
        box-sizing : border-box;
        text-align : center;
        }
    }
`
const BodyImgWrapper = styled.div`
    position : relative; 
    width : 100%;
    height : 100%;
`
const MobileImg = styled.img`
    display : block;
    width : 44vw;
    position : absolute; 
    bottom : 0px;
    right : -120px;
    @media screen and (max-width: 768px) 
    { & { 
        display : none;
    }
    }
`
const ContentText = styled.span`
    display : block;
    color : ${colors.White};
    font-size : 23px; 
    bottom : 20px;
    font-family : Dream4;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 3.5vw; 
    }
    }
`
const ContentText2 = styled.span`
    margin-top : 30px;
    display : block;
    color : ${colors.White};
    font-size : 33px; 
    bottom : 20px;
    font-family : Dream4;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 4.5vw; 
    }
    }
`
const ContentText3 = styled.span`
    display : inline-block;
    color : ${colors.textOrenge};
    font-size : 28px; 
    font-family : Dream5;
    z-index : 15;
    text-align : center;
    background-color : rgba(49,49,49,0.8);
    width: 100%;
    padding : 10px;
    border-radius : 10px;
    @media screen and (max-width: 768px) 
    { & { 
        margin-top : 0;
        font-size : 13px; 
        box-sizing : border-box;
        }
    }
`
const ContentWrapper = styled.div`
    display : inline-block;
    position : absolute;
    margin-top : 6%;
    z-index : 10;
    width : 55%;
    @media screen and (max-width: 768px) 
    { & { 
        position : relative;
        width : 100%;
        margin : 0 auto;
        left : 0;
    }
    }
`
export default Body1;