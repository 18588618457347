const colors ={
    White : "#ffffff",
    Black : "#000000",
    SkyBlue : "#00B1FF",
    backGray : "#444341",
    backBlue : "#003B77",
    backBlueGray : "#A6BACF",
    listBackBlue : "003B77",
    textGray : "#313131",
    backGrayOpacity : "#494949",
    textOrenge : "#FFC400",
    textTitle : "#232323",
    textContent : "#4E4E4E",
    textRed : "#FF0000",
    background : "#F9F9F9",
    backgroundBlue : "#E2F5FA",
    backgroundPuple : "#F1F0FA",
    backgroundSky : "#EDF3F9",
    Circle:"#9EE0FB"
}
export default colors;