import React, {  useMemo, useState } from 'react';
import { createContext } from "react";

export const PointStateContext = createContext({});
export const PointStateProvider = (props)=>{
    const [PointETH, SetPointETH] = useState();
    const [PointAddress,SetPointAddress] = useState();
    const [PointKRW,SetPointKRW] = useState();
    return (
        <PointStateContext.Provider value = {{PointETH : PointETH, SetPointETH : SetPointETH, PointAddress : PointAddress, SetPointAddress : SetPointAddress, PointKRW : PointKRW, SetPointKRW: SetPointKRW }}>
            {props.children}
        </PointStateContext.Provider>
    )
}
export default PointStateContext;