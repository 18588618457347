import {react,useEffect,useState,useRef,useContext} from "react";
import styled from "styled-components"
import back from "../img/backimg.png";
import GetPost from "../hook/GetPost";
import Header from "./Header";
import colors from "./colors";
import {useLocation} from "react-router";
const AngelCenterPost=()=>{
    const location = useLocation();
    const Data = location.state.data;
    const timestamp = Data.time;
    var dateValue = new Date(timestamp);
    var realDate = dateValue.getFullYear()+"/"+(dateValue.getMonth()+1)+"/"+dateValue.getDate();

    console.log(Data)
    return(
        <div>
        <Header/>
        <BodyWrapper>
            <BodyImgWrapper/>
            <Wrapper>
            <IndexCircle/>
                <ContentWrapper>
                    <ContentText>Angel 센터</ContentText>
                    <List>
                        <Thead>
                        <Tr>
                            <Th>번호 : {Data.notice_id}</Th>
                            <Th>제목 : {Data.title}</Th>
                            <Th>작성일 :{realDate} </Th>
                        </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td> {Data.contents}</Td>
                                
                            </Tr>
                        </Tbody>
                    </List>
                </ContentWrapper>
            </Wrapper>
        </BodyWrapper>
        </div>
    );

}
const PageNumberWrapper = styled.div`
    width : 100%;
    text-align : center;
    padding : 20px;
`
const PageNumberListTr = styled.span`
    width : 70px;
    padding : 20px; 
    cursor : pointer;
`
const Wrapper = styled.div`
position : relative;
`
const List =  styled.table`
    margin : 40px 0;
    padding : 0; 
    border : 1px solid #AAAAAA;
    font-family : Dream3;
    width  :100%;
    border-collapse: collapse;

`
const Thead = styled.thead`
    text-align : center;
    background-color :#CFCFCF;
`
const Tbody = styled.tbody`
text-align : left;
`
const Tr = styled.tr`
border-bottom : 1px solid #AAAAAA;

`
const Th = styled.th`
padding : 15px 20px;
border-right : 1px solid #AAAAAA;
&:nth-child(2){
    width : 70%;
}
`
const Td = styled.td.attrs({
    colSpan:3
})`
border-left : 1px solid #AAAAAA;
padding : 15px 20px;
text-align : left;

&:first-child{
    width : 7%;
}
&:nth-child(2){
    width : 70%;
    text-align : left ;
}
&:nth-child(3){
    width : 15%;
}
`
const IndexCircle = styled.div`
    position : absolute;
    background-color : ${colors.Circle};
    width : 60px;
    height : 60px;
    border-radius : 100%;
    top : -5px;
    left : 5px;
    @media screen and (max-width: 768px) 
    { & { 
        background-color :rgba(0,0,0,0);
        text-align : center;
        }
    }
`
const ContentWrapper = styled.div`
    position : relative;
    display : block;
    margin : 100px 0;
    @media screen and (max-width: 768px) 
    { & { 
        margin : 100px 0;
        text-align : center;
        }
    }
`
const ContentText = styled.span`
    display : block;
    margin-left : 30px;
    color : ${colors.textTitle};
    font-size : 37px; 
    bottom : 20px;
    font-family : Dream6;
    z-index : 10;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 23px; 
        }
    }
`
const BodyWrapper = styled.div`
    padding : 0 150px;
    display : block;
    width : calc(100% - 300px);
    min-width :1215px;  
    text-align : left;
    background-color : ${colors.background};
    height : 100vh;
`
const BodyImgWrapper = styled.div`
    position : relative; 
    width : calc(100% + 300px);
    height : 130px;
    left : -150px;
    background-image : url(${back});
    background-size : 100% auto;
    background-position-y :300px;

`

export default AngelCenterPost;