import {react,useEffect,useState,useRef, useContext} from "react";
import styled from "styled-components"
import colors from "./colors.js"
import DepositFunc from "../hook/Deposit.js";
import QRimg from "../img/addressQR.png"
import axios from "axios";
import { server } from "../hook/constValue.js";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReceiptModal from "./ReceiptModal.js";
const SpacialJoin=({EthPrice,email})=>{
        const [ButtonState,SetButtonState] = useState(false);
        const [Receipt,SetReceipt] = useState(false);
        const [KRWValue, SetKRWValue] = useState(0);
        const [ETHValue, SetETHValue] = useState(0);
        const [Address, SetAddress] = useState();
        useEffect(()=>{
            const CurrentKRW = 3800000;
            const KRW = CurrentKRW.toLocaleString('ko-KR', {
                maximumFractionDigits: 4
              });
            SetKRWValue(KRW)
            
        },[])
        useEffect(()=>{
            if(EthPrice!=undefined){
                const CurrentKRW = 3800000;
                const ETH = parseInt(CurrentKRW)/EthPrice;
                const CalcedValue = Math.ceil((ETH*10000))/10000;
                SetETHValue(CalcedValue);
            }
        },[EthPrice])
        const Spacial_DepositHandler = ()=>{
            DepositFunc(email, Address,ETHValue,"event",true,false).then(()=>{alert("관리자가 확인한 후 24시간 이내에 처리하겠습니다. 일주일 이상 포인트 충전이 안 되는 경우 아래 이메일로 문의해주세요. cada8585@daum.net")});;    
        }
        return (
            <RelativeBox>
            <BoxTitle2>입금자 지갑 주소 : </BoxTitle2>
                    <InputBox onChange={(e)=>{SetAddress(e.target.value)}}/>
                    <BoxTitle2>입금양(ETH) : </BoxTitle2>
                    <InputBox value={ETHValue} readOnly/>
                    <BoxTitle2>입금양(KRW) : </BoxTitle2>
                    <InputBox value={KRWValue} readOnly/>
                    <QRImgDiv>
                        <QRTitle>입금 QR</QRTitle>
                        <ImgIcon src={QRimg}/>
                    </QRImgDiv>
                    <Hr/>
                    <QRInputSpan> 입금할 주소 : 0xca3B83433D3Ae521B3e1b6884bf8f0Ab078131c5
                    <CopyToClipboard text="0xca3B83433D3Ae521B3e1b6884bf8f0Ab078131c5">
                <BoxContent4 onClick={()=>{alert("주소가 복사되었습니다.")}}>복사</BoxContent4>
                </CopyToClipboard>
                    </QRInputSpan>
                    <QRTitle>VIP 가입 가격은 1년에 380만 원 입니다. <br/>매매 수수료는 무료입니다.</QRTitle>
                    <FlexDiv>
                    <InputCheckBox type="checkbox" checked={ButtonState} onChange={(e)=>{SetButtonState(!ButtonState)}}/><QRTitle>결제후 환불이 불가능한 서비스임을 숙지하였습니다.</QRTitle>
                    </FlexDiv>
                    <ButtonBox disabled={!ButtonState} State={ButtonState} onClick={()=>{Spacial_DepositHandler()}}>입금 하기</ButtonBox>
            </RelativeBox>
        )
    }
    const SpacialJoinKRW=({email})=>{
        const [ButtonState,SetButtonState] = useState(false);
        const [Receipt,SetReceipt] = useState(false);
        const [KRWValue, SetKRWValue] = useState(0);
        const [Address, SetAddress] = useState();
        const [ReceiptModalState,SetReceiptModal] = useState(false)
        const [ReceiptType,SetReceiptType] = useState(null);
        const [ReceiptInfo,SetReceiptInfo] = useState(null);
        
        useEffect(()=>{
            const CurrentKRW = 3800000;
            const KRW = CurrentKRW.toLocaleString('ko-KR', {
                maximumFractionDigits: 4
              });
            SetKRWValue(KRW)
        },[])
        const Spacial_DepositHandler = ()=>{
            if(Receipt){
                DepositFunc(email, Address,3800000,"event",false,Receipt,ReceiptInfo,ReceiptType).then(()=>{alert("관리자가 확인한 후 24시간 이내에 처리하겠습니다. 일주일 이상 포인트 충전이 안 되는 경우 아래 이메일로 문의해주세요. cada8585@daum.net")});;    
            }
            else{
                DepositFunc(email, Address,3800000,"event",false,Receipt,"null","null").then(()=>{alert("관리자가 확인한 후 24시간 이내에 처리하겠습니다. 일주일 이상 포인트 충전이 안 되는 경우 아래 이메일로 문의해주세요. cada8585@daum.net")});;    
            }
        }
        return (
            <RelativeBox>
            <BoxTitle2>입금자 이름 : </BoxTitle2>
                    <InputBox onChange={(e)=>{SetAddress(e.target.value)}}/>
                    <BoxTitle2>입금양(KRW) : </BoxTitle2>
                    <InputBox value={KRWValue}/>
                    <Hr/>
                    <QRTitle>입금 계좌 : 351-1200-5819-03 농협 백랑기</QRTitle>
                    <QRTitle>VIP 가입 가격은 1년에 380만 원 입니다. <br/>매매 수수료는 무료입니다.</QRTitle>
                    <FlexDiv>
                    <InputCheckBox type="checkbox" checked={ButtonState} onChange={(e)=>{SetButtonState(!ButtonState)}}/><QRTitle>결제후 환불이 불가능한 서비스임을 숙지하였습니다.</QRTitle>
                    </FlexDiv>
                    <FlexDiv>
                    <InputCheckBox type="checkbox" checked={Receipt} onChange={(e)=>{SetReceipt(!Receipt);if(!Receipt){SetReceiptModal(true)}}}/><QRTitle>현금영수증 신청</QRTitle>
                    </FlexDiv>
                    <ButtonBox disabled={!ButtonState} State={ButtonState} onClick={()=>{Spacial_DepositHandler()}}>입금 하기</ButtonBox>
                    {ReceiptModalState?<ReceiptModal SetModalState={SetReceiptModal} SetReceiptInfo={SetReceiptInfo} SetReceiptType={SetReceiptType}/>:null}
            </RelativeBox>
        )
    }
    export const Special = ({HowDeposit, email})=>{
        const [EthPrice, setETHprice] = useState();
        useEffect(async ()=>{
            const Price = await axios.post(server+"/getEthPrice").then((res)=>{return res})
            setETHprice(await Price.data);
        },[])
        if(HowDeposit){
            return <SpacialJoin email={email} EthPrice={EthPrice}/>
        }
        else{
            return <SpacialJoinKRW email={email}/>
        }
    }
const RelativeBox = styled.div`
    position : relative;
`
const FlexDiv = styled.div`
    display : flex-box;
    flex-direction : row;
    flex-wrap: nowrap;
    align-items : center;
    justify-content : center;
`
const InputCheckBox = styled.input`
    height : 15px;
    border : 1px solid black;
    width : 15px;
    position : relative;
    top : 5px;
    vertical-align : top;
    @media screen and (max-width: 768px) 
    {
        top : 3px;
    }
`
const InputBox= styled.input`
    width :calc(100% - 220px);
    height : 30px;
    padding : 5px 15px;
    vertical-align : middle;
    border : none;
    margin : 10px ;
    font-family : Dream4;
    border-bottom : 1px solid black;
    border-radius : 20px;
    @media screen and (max-width: 768px) 
{ & { 
    min-width :0;  
    width : 100%;
    box-sizing : border-box;
    text-align : left;
    margin : 20px 0;
    }
}
`
const QRImgDiv = styled.div`
    width : 100%;
    text-align : left;
    @media screen and (max-width: 768px) 
{ & { 
    min-width :0;  
    width : 100%;
    box-sizing : border-box;
    margin : 20px 0;
    }
}
`
const ButtonBox = styled.button`
    height : 60px; 
    width : 240px;
    cursor:pointer;
    border-radius : 40px;
    background-color : ${props=>{
        if(props.State){
            return colors.SkyBlue
        }
        else{
            return "gray"
        }
    }};
    font-family : Dream5;
    font-size : 24px;
    border : none;
    text-align : center;
    color : white;
    display : block;
    margin :20px auto;
    box-shadow: 3px 2px 5px rgba(0, 0, 0,0.2);
    `
const ImgIcon = styled.img`
    width : 150px;
    height : 150px;
    display : inline-block;
    margin : 20px 0 ;
    position : relative;
    left : calc(100% - 220px);
    @media screen and (max-width: 768px) 
    { & { 
        margin : 20px auto;
        margin-top : 50px;
        left : calc(100% - 210px);
            }
    }
`
const QRTitle = styled.span`
    display : inline-block;
    color : ${colors.White};
    font-size : 15px;
    font-family : Dream5;
    margin : 5px 0;
    vertical-align : top;
    word-wrap: break-word;
    width : 90%;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 90%;
        word-wrap: break-word;
    }
    }
`
const QRInputSpan = styled.span`
    display : inline-block;
    color : ${colors.White};
    font-size : 15px;
    font-family : Dream5;
    margin : 15px 0;
    vertical-align : top;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 100%;
        word-wrap: break-word;
    }
    }
`
const BoxTitle2 = styled.span`
    display : inline-block;
    color : ${colors.White};
    font-size : 15px;
    font-family : Dream5;
    margin : 15px 0;
    width : 160px;
    vertical-align : middle;
    text-align : left;
    @media screen and (max-width: 768px) 
{ & { 
    font-size : 15px;
    display : inline-block;
    padding : 0; 
    margin : 0;

    }
}
`
const BoxContent4 = styled.span`
display : inline-block;
color : ${colors.White};
font-size : 15px;
font-family : Dream5;
margin : 0px 3px;
top : -3px;
position : relative;
padding : 3px;
vertical-align : middle;
border : 1px solid white;
@media screen and (max-width: 768px) 
{ & { 
    font-size : 15px;
    display : inline-block; 
    margin : 3px ;
    box-sizing : border-box;
    text-align : left;
    }
}
`
const Hr = styled.div`
    margin : 10px 0;
    widht : 90%;
    border-top: 1px solid ${colors.White};
`
export default Special;
