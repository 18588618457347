import {react,useEffect,useState,useRef} from "react";
import styled from "styled-components"
import colors from "./colors.js"
import ChangeLevelFunc from "../hook/ChangeLevel.js";
const Type1 = ({RefObject})=>{
    return(<TextWrapper><SelectText>휴대폰 번호 : </SelectText><InputBox ref={RefObject} placeholder="- 없이 입력하세요."/></TextWrapper>)
}
const Type2=({RefObject})=>{
    return(<TextWrapper><SelectText>사업자 번호 : </SelectText><InputBox ref={RefObject} placeholder="- 없이 입력하세요."/></TextWrapper>)
}

const ReceiptModal = ({SetModalState,SetReceiptInfo,SetReceiptType})=>{
    const [SelectedState, SetSelectedState] = useState(true);
    const [Agree , SetAgree] = useState(false);
    const UserPhone =useRef();
    const UserAccount =useRef();
    const OnClickHandler = ()=>{
        if(SelectedState==true){
            SetReceiptInfo(UserPhone.current.value)
            SetReceiptType(true)//true 소득
        }
        else{
            SetReceiptInfo(UserAccount.current.value)
            SetReceiptType(false)//false  지출
        }
        SetModalState(false)
    }
    return (
        <Box>
            <BoxSpanWhite>
                 현금영수증 발급 정보 등록
                 </BoxSpanWhite>
                
                <TextWrapper> <SelectText>발급용도 : </SelectText><RadioInput type="radio" checked={SelectedState} value={true} name="receipt" onChange={(e)=>{SetSelectedState(true)}}/><SelectText>소득공제용</SelectText>
                <RadioInput type="radio" value={false} name="receipt" onChange={(e)=>{SetSelectedState(false)}}/><SelectText>지출증빙용</SelectText></TextWrapper>
                <br/>
                {SelectedState?<Type1 RefObject={UserPhone}/>:<Type2 RefObject={UserAccount}/>}
                <TextWrapper><InputCheckBox type="checkbox" checked={Agree} onChange={()=>{SetAgree(!Agree)}}/><QRTitle> 위 현금영수증 신청 정보를 저장하여 자동으로 국세청으로 통보되는 것에 동의합니다.</QRTitle></TextWrapper>
                <Hr/>
                <QRTitlespecial>
                1. 현금영수증은 하루동안 발생한 포인트 충전금액 및 회비를 합산하여 발급됩니다
                </QRTitlespecial>
                <QRTitlespecial>
                2. 발급정보등록이전의 내역은 현금영수증이 불가합니다
                </QRTitlespecial>
                <QRTitlespecial>
                   3. 현금영수증 조회는 국세청 홈페이지<a href="https://www.hometax.go.kr" style={{color : "white"}}>(https://www.hometax.go.kr)</a>에서 확인해주세요.
                </QRTitlespecial>
                <Hr/>
                <MarginDiv>
            <ButtonBox State={Agree} disabled={!Agree} onClick={()=>{OnClickHandler()}}>등록</ButtonBox>
            <ButtonBox State={true} onClick={()=>{SetModalState(false)}}>취소</ButtonBox>
        </MarginDiv>
        </Box>
    )
    
}
const Hr = styled.div`
    margin : 10px 0;
    widht : 90%;
    border-top: 1px solid ${colors.White};
`
const QRTitlespecial = styled.span`
    display : inline-block;
    word-break: break-all;
    color : ${colors.textOrenge};
    font-size : 12px;
    font-family : Dream5;
    margin : 5px 0;
    vertical-align : top;
    word-wrap: break-word;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 90%;
        word-wrap: break-word;
    }
    }
`
const QRTitle = styled.span`
    display : inline-block;
    color : ${colors.White};
    font-size : 15px;
    font-family : Dream5;
    margin : 3px 5px;
    vertical-align : top;
    word-wrap: break-word;
    width : 90%;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 90%;
        word-wrap: break-word;
    }
    }
`
const InputCheckBox = styled.input`
    height : 15px;
    width : 15px;
    position : relative;
    top : 4px;
    vertical-align : top;
    @media screen and (max-width: 768px) 
    {
        top : 3px;
    }
`
const InputBox = styled.input`
width :59 %;
height : 20px;
padding : 5px 15px;
vertical-align : middle;
border : none;
margin : 10px ;
font-family : Dream4;
border-bottom : 1px solid black;
border-radius : 20px;
@media screen and (max-width: 768px) 
{ & { 
min-width :0;  
width : 50%;
box-sizing : border-box;
text-align : left;
margin : 20px 0;
}
}

`
const MarginDiv = styled.div`
    width : 100%;
    text-align :center;
`
const TextWrapper = styled.div`
    display : inline-block;
    width : 100%;
`
const SelectText = styled.span`
    font-size : 18px;
    color : ${colors.White};
font-family : Dream6;
`
const RadioInput = styled.input`
    width : 15px;
    height : 15px;

`
const ButtonBox = styled.button`
    height : 50px; 
    width : 120px;
    border-radius : 10px;
    background-color : ${
        props=>{
            if(props.State){
                return colors.SkyBlue
            }
            else{
                return "gray"
            }
        }
    };
    font-family : Dream5;
    font-size : 24px;
    border : none;
    text-align : center;
    color : white;
    display : inline-block;
    margin :10px;
    cursor:pointer;
    `
const Box = styled.div`
    background-color : rgba(30,30,30,1);
    width : 100%;
    border-radius : 20px;
    padding : 30px 35px;
    box-sizing : border-box;
    z-index : 10;
    position :absolute ;
    top : 0;
    @media screen and (max-width: 768px) 
    { & { 
        width : 100%;
        top : 0%;
        padding : 20px ;
        
    }
    }
`
const BoxSpanWhite = styled.span`
font-size : 20px;
color : ${colors.White};
font-family : Dream6;
display : inline-block;
padding-bottom : 20px;
text-align : center;
width : 100%;
@media screen and (max-width: 768px) 
{ & { 
    font-size : 15px;
}
}
`
const BoxSpanOrenge = styled.span`
font-size : 30px;
color : ${colors.textOrenge};
font-family : Dream6;

`
export default ReceiptModal;