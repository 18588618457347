import {react,useEffect,useState,useRef} from "react";
import styled from "styled-components"
import colors from "./colors.js"
import ChangeInfoFunc from "../hook/ChangeInfo.js";
const PwChangeModal = ({SetModalState,email})=>{
    const [SelectedState, SetSelectedState] = useState(null);
    const OnClickHandler = ()=>{
        if(SelectedState==null||SelectedState==undefined||SelectedState==""){
            alert("변경하실 비밀번호를 입력해주세요!");
        }
        else{
            ChangeInfoFunc(email,SelectedState,"pw").then((res)=>{alert("다음 로그인 시 반영됩니다.");SetModalState(false)});
        }
    }
    return (
        <Box>
            <BoxSpanWhite>
                 변경하실 비밀번호를 입력해주세요. (영문/숫자/특수문자 조합 8글자 이상)
                 </BoxSpanWhite>
            <InputBox onChange={(e)=>{SetSelectedState(e.target.value)}}/>
            <ButtonBox onClick={()=>{OnClickHandler()}}>확인</ButtonBox>
            <ButtonBox onClick={()=>{SetModalState(false)}}>닫기</ButtonBox>
        </Box>
    )

}
const TextWrapper = styled.div`
    display : inline-block;
    width : 40%;
    margin : 5% 5%;
    text-align: center;
`
const SelectText = styled.span`
    font-size : 20px;
    color : ${colors.White};
font-family : Dream6;
`
const InputBox = styled.input`
width :calc(100% - 220px);
height : 30px;
padding : 5px 15px;
vertical-align : middle;
border : none;
margin : 10px ;
font-family : Dream4;
border-bottom : 1px solid black;
border-radius : 20px;
@media screen and (max-width: 768px) 
{ & { 
min-width :0;  
width : 100%;
box-sizing : border-box;
text-align : left;
margin : 20px 0;
}
}

`
const ButtonBox = styled.button`
    height : 50px; 
    width : 200px;
    border-radius : 10px;
    background-color : ${colors.SkyBlue};
    font-family : Dream5;
    font-size : 24px;
    border : none;
    text-align : center;
    color : white;
    display : inline-block;
    margin :10px;
    cursor:pointer;
    `
const Box = styled.div`
    background-color : rgba(49,49,49,0.8);
    width : 880px;
    border-radius : 20px;
    margin : 120px 0;
    padding : 50px 100px;
    box-sizing : border-box;
    position : absolute;
    top : calc(10% - 135px);
    left : calc(50% - 440px);
    z-index : 10;
    @media screen and (max-width: 768px) 
    { & { 
        width : 80%;
        top : 90%;
        padding : 50px ;
        left : 10vw;
    }
    }
`
const BoxSpanWhite = styled.span`
font-size : 20px;
color : ${colors.White};
font-family : Dream6;
display : inline-block;
padding-bottom : 30px;
text-align : center;
width : 100%;
@media screen and (max-width: 768px) 
{ & { 
    font-size : 15px;
}
}
`
const BoxSpanOrenge = styled.span`
font-size : 30px;
color : ${colors.textOrenge};
font-family : Dream6;

`
export default PwChangeModal;