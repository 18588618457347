import {react,useEffect,useState,useRef, useContext} from "react";
import styled from "styled-components"
import colors from "./colors.js"
import backCoin from "../img/coinBack.png";
import Header from "./Header";
import { useHistory } from "react-router";
import Logo from "../img/LogoCircleBlack.png";
import { CheckEmailCodeFunc,CheckEmailFunc,CheckNicknameFunc,JoinFunc } from "../hook/Join.js";
const WrapRow = styled.div`
    display : block;
    margin : 10px 0;
`
const Join=()=>{
    const history = useHistory();
    const Nick = useRef();
    const Email = useRef();
    const Pw = useRef();
    const PwCheck = useRef();
    const EmailCode = useRef();
    const [EmailCodeIs,SetEmailCodeIs] = useState(false);
    const [NicknameIs,SetNicknameIs] = useState(false);
    const [JoinButtonState,SetJoinButtonState] = useState(false);
    const name = useRef();
    const [gender,setGender] = useState();
    const phone = useRef();
    const birthday = useRef();
    const CheckNicknameHandler =()=>{
        CheckNicknameFunc(Nick.current.value).then((res)=>{
            if(res==true){
                alert("사용할 수 있는 닉네임입니다.")
                SetNicknameIs(true)
            }
            else{
                alert("사용할 수 없는 닉네임입니다.")
                SetNicknameIs(false)
            }
        })
    }
    const CheckEmailHandler = ()=>{
        alert("이메일 인증 코드가 발송되었습니다.");
        CheckEmailFunc(Email.current.value).then((res)=>{
            console.log(res)
            if(res==false){
                alert("이미 가입된 이메일입니다. 다른 이메일로 다시 시도해주세요.")
            }
            else{
            }
        });
    }
    const CheckEmailCodeHandler = ()=>{
        CheckEmailCodeFunc(Email.current.value,EmailCode.current.value).then((res)=>{
            if(res==true){
                alert("이메일 인증이 완료되었습니다.")
                SetEmailCodeIs(true)
            }
            else{
                alert("인증번호가 틀렸습니다.")
                SetEmailCodeIs(false)
            }
        })
    }
    const JoinHandler = ()=>{
        if(JoinButtonState){
            if(Pw.current.value!=PwCheck.current.value){
                alert("비밀번호와 비밀번호 확인 입력 값이 다릅니다.")
            }
            else if(Pw.current.value==""){
                alert("비밀번호를 입력해주세요.")
            }
            else if(PwCheck.current.value==""){
                alert("비밀번호 확인 값을 입력해주세요.")
            }
            else{
                console.log([Email.current.value,Pw.current.value,EmailCode.current.value,Nick.current.value,name.current.value,phone.current.value,birthday.current.value,gender])
                JoinFunc(Email.current.value,Pw.current.value,EmailCode.current.value,Nick.current.value,name.current.value,phone.current.value,birthday.current.value,gender).then(()=>{
                    alert("회원가입이 완료되었습니다. 로그인 페이지로 이동합니다.")
                    window.open('https://coinangel.co.kr:1004/app_download', '_blank')
                    history.push("/Login")
                })
            }
        }
        else{
            if(EmailCodeIs==false){
                alert("이메일 인증을 해주세요.")
            }
            else if(NicknameIs==false){
                alert("닉네임 중복확인을 해주세요.")
            }
            else if(EmailCodeIs==false&&NicknameIs==false){
                alert("닉네임 중복확인과 이메일 인증을 해주세요.")
            }
        }
    }
    const PhoneNumberHandler = (e)=>{
        e.target.value=e.target.value.toString().replace(/[^0-9]/g, "").replace(
            /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
            "$1-$2-$3"
          )
    }
    const BrithdayHandler = (e)=>{
        e.target.value=e.target.value.toString().replace(/[^0-9]/g, "").replace(/([0-9]{4})([0-9]{2})([0-9]{2})/,"$1-$2-$3")
    }
    useEffect(()=>{
        if(EmailCodeIs==true&&NicknameIs==true){
            SetJoinButtonState(true)
        }
    },[EmailCodeIs,NicknameIs])
    return(
        <BodyWrapper>
            <Header/>
            <BodyImgWrapper>
            <BigCircle/>
            <MiddleCircle/>
            <Box>
                <BoxSpanWhite>
                    회원가입
                </BoxSpanWhite>
                <ContentWrapper>
                    <WrapRow>
                <BoxSpanOrenge>
                    이름
                </BoxSpanOrenge>
                <InputBox ref={name}/>
                </WrapRow>
                <WrapRow>
                <BoxSpanOrenge>
                    성별 
                </BoxSpanOrenge>
                <WrapperGender>
                <RadioBox type="radio" id="gender" name="gender" onChange={(e)=>{console.log(e.target.value);setGender(e.target.value)}} value="여성"/> 
                <BoxSpanWhite2>여성</BoxSpanWhite2>
                </WrapperGender>
                <WrapperGender>
                <RadioBox type="radio" id="gender" name="gender" onChange={(e)=>{console.log(e.target.value);setGender(e.target.value)}} value="남성"/>
                <BoxSpanWhite2>남성</BoxSpanWhite2>
                </WrapperGender>
                </WrapRow>
                <WrapRow>
                <BoxSpanOrenge>
                    생일 
                </BoxSpanOrenge>
                <InputBox onChange={(e)=>{BrithdayHandler(e)}} maxLength={10} placeholder="xxxx-xx-xx" ref={birthday}/>
                </WrapRow>
                <WrapRow>
                <BoxSpanOrenge>
                    전화번호 
                </BoxSpanOrenge>
                <InputBox ref={phone} type="text" maxLength={13} placeholder="010-xxxx-xxxx" onChange={(e)=>{PhoneNumberHandler(e)}}/>
                </WrapRow>
                <WrapRow>
                <BoxSpanOrenge>
                    별명
                </BoxSpanOrenge>
                <InputBox2 ref={Nick}/>
                <MarginDiv2><ButtonBox onClick={()=>{
                    CheckNicknameHandler()
                    }}>별명중복확인</ButtonBox></MarginDiv2>
                </WrapRow>
                <WrapRow>
                <BoxSpanOrenge>
                    Email
                </BoxSpanOrenge>
                <InputBox2 ref={Email}/>
                <MarginDiv2><ButtonBox onClick={()=>{
                    CheckEmailHandler();
                }}>인증번호보내기</ButtonBox></MarginDiv2>
                </WrapRow>
                <WrapRow>
                <BoxSpanOrenge>
                    비밀번호
                </BoxSpanOrenge>
                <InputBox ref={Pw} type="password"/>
                </WrapRow>
                <WrapRow>
                <BoxSpanOrenge>
                    비밀번호 확인
                </BoxSpanOrenge>
                <InputBox ref= {PwCheck} type="password"/>
                </WrapRow>
                <WrapRow>
                <BoxSpanOrenge>
                    이메일 인증번호
                </BoxSpanOrenge>
                
                <InputBox2 ref={EmailCode} />
                <MarginDiv2><ButtonBox onClick={()=>{
                    CheckEmailCodeHandler();
                }}>인증</ButtonBox></MarginDiv2><br/>
                </WrapRow>
                <MarginDiv><ButtonBox2 state={JoinButtonState} onClick={JoinHandler}>가입</ButtonBox2></MarginDiv>
                    <MobileImg2 src={backCoin}/>
                </ContentWrapper>
            </Box>
            </BodyImgWrapper>
        </BodyWrapper>
    );

}
const MarginDiv = styled.div`
width : 100%;
text-align : center;
`
const MarginDiv2 = styled.div`

text-align : left;
display :inline-block;
vertical-align : top;
@media screen and (max-width: 768px) 
{ & { 
    width : 30%;
    margin : 0;
    text-align : center;
    }
}
`
const ButtonBox2 = styled.button`
cursor:pointer;
    height : 50px; 
    padding : 5px 15px;
    border-radius : 30px;
    background-color : ${
        props=>{
            if(props.state==false){
                return "gray"
            }
            else{
                return colors.SkyBlue
            }
        }
    };
    font-family : Dream5;
    font-size : 24px;
    border : none;
    text-align : center;
    color : white;
    display : inline-block;
    margin:10px auto;
    
    vertical-align : top;
    box-shadow: 3px 2px 5px rgba(0, 0, 0,0.2);
    @media screen and (max-width: 768px) 
    { & { 
        padding : 5px 10px;
        height : 100%;
        font-size : 20px;

        box-sizing : border-box;
        }
    }
`
const ButtonBox = styled.button`
cursor:pointer;
    height : 44px; 
    padding : 5px 15px;
    border-radius : 30px;
    background-color : ${
        props=>{
            if(props.state==false){
                return "gray"
            }
            else{
                return colors.SkyBlue
            }
        }
    };
    font-family : Dream5;
    font-size : 24px;
    border : none;
    text-align : center;
    color : white;
    display : inline-block;
    margin:10px 5px;
    
    vertical-align : top;
    box-shadow: 3px 2px 5px rgba(0, 0, 0,0.2);
    @media screen and (max-width: 768px) 
    { & { 
        padding : 6px 10px;
        height : 100%;
        font-size : 8px;
        margin : 0;
        width : 90%;
        vertical-align : top;
        box-sizing : border-box;
        }
    }
`
const RadioBox = styled.input``
const InputBox= styled.input`
    width :70%;
    height : 25px;
    padding : 10px 15px;
    vertical-align : middle;
    border-radius : 30px;
    border : none;
    margin : 10px 0 ;
    font-family : Dream4;
    @media screen and (max-width: 768px) 
    { & { 
        width : 60%;
        height : 100%;
        padding :5px 10px;
        margin: 0;
        box-sizing : border-box;
        }
    }
`
const InputBox2= styled.input`
    width :45%;
    height : 25px;
    padding : 10px 15px;
    border-radius : 30px;
    border : none;
    font-family : Dream4;
    margin : 10px 0;
    vertical-align : top;
    @media screen and (max-width: 768px) 
    { & { 
        width : 31%;
        padding :5px 10px;
        box-sizing : border-box;
        vertical-align : top;
        margin : 0;
        }
    }
`
const BodyWrapper = styled.div`
    min-width :1215px;  
    padding : 0 150px;
    display : block;
    min-height : 100vh;
    width : calc(100% - 300px);
    text-align : left;
    overflow : hidden;
    & > div:first-child{
        width : calc(100% - 300px);
        padding : 25px 0;
        @media screen and (max-width: 768px) 
        { 
            width : 100%;
        }   
        
    }
    & > div:first-child div{
        right : 0;
    }
    & div ul li {
        color : ${colors.textGray};
        @media screen and (max-width: 768px) 
        { 
            color : ${colors.White};
        } 
    } 
    & div ul:first-child li {
        color : ${colors.textGray};
    } 
    & div ul:first-child div {
        background-image : url(${Logo});
    } 
    @media screen and (max-width: 768px) 
    { & { 
        padding : 0 ;
        min-width :0;  
        width : 100%;
        box-sizing : border-box;
        }
    }
`
const BodyImgWrapper = styled.div`
    position : relative; 
    width : 100%;
    height : 100%;
`
const MobileImg2 = styled.img`
    display : block;
    width : 350px;
    position : absolute; 
    top : 870px;
    right : 0;
    @media screen and (max-width: 768px) 
    { & { 
        width : 0;
        }
    }
`
const ContentWrapper = styled.div`
    display : block;
    text-align : left;
`
const BigCircle = styled.div`
    position : absolute;
    background-color : ${colors.backgroundBlue};
    width : 1300px;
    height : 1300px;
    border-radius : 100%;
    z-index : -1;
    top : -1000px;
    left : -400px;
    @media screen and (max-width: 768px) 
    { & { 
        top : -300px;
        left : -100px;
        width : 350px;
        height : 350px;
        }
    }
`
const MiddleCircle = styled.div`
    position : absolute;
    background-color : ${colors.backgroundPuple};
    width : 28vw;
    height : 28vw;
    border-radius : 100%;
    z-index : -1;
    bottom : -15vw;
    right : -180px;
    @media screen and (max-width: 768px) 
    { & { 
        bottom : -300px;
        right : -100px;
        width : 250px;
        height : 250px;
        }
    }
`

const Box = styled.div`
    background-color : rgba(49,49,49,0.8);
    width : 1150px;
    border-radius : 20px;
    margin : 220px auto;
    padding : 50px 100px;
    box-sizing : border-box;
    position : relative;
    @media screen and (max-width: 768px) 
    { & { 
        padding : 25px;
        width : calc(100% - 80px);
        box-sizing : border-box;
        margin : 120px 40px;
        }
    }
`
const BoxSpanWhite = styled.span`
font-size : 35px;
color : ${colors.White};
font-family : Dream6;
display : inline-block;
padding-bottom : 30px;
@media screen and (max-width: 768px) 
{ & { 
    width : 100%;
    height : 100%;
    text-align : center;
    font-size : 25px;

}
}
`
const BoxSpanOrenge = styled.span`
display : inline-block;
width : 25%;
font-size : 25px;
color : ${colors.textOrenge};
font-family : Dream6;
vertical-align :top;
text-align : left;
margin : 10px 0;
@media screen and (max-width: 768px) 
{ & { 
    width : 39%;
    font-size : 15px;
    margin : 0;
    text-align : left;
}
}
`
const WrapperGender = styled.div`
display :inline-block;
width : 36.5%;
@media screen and (max-width: 768px) 
{ & { 
    width : 30%;
}
}
`
const BoxSpanWhite2 = styled.span`
display : inline-block;

font-size : 25px;
color : white;
font-family : Dream6;
vertical-align : middle;
text-align : left;
@media screen and (max-width: 768px) 
{ & { 
    width : 40%;
    font-size : 15px;
    text-align : left;
    
}
}
`
export default Join;