import styled from "styled-components"
import colors from "./colors.js"
import back from "../img/backimg.png";
const MyPageInfo=()=>{
    return (
        <div>
            
            <BoxBlue>회원등급 구분</BoxBlue>
            <QRTitle>일반 회원</QRTitle><br/>
            <QRTitle>
            <QRTitle22>멤버스 (members)</QRTitle22> <br/>
            1) 연회비 : 무료 <br/>
            2) 프로그램 수수료 : 수익금의 10%<br/>
            </QRTitle>

            <QRTitle>
            <QRTitle22>프렌드 (friend)</QRTitle22> <br/>
            1) 연회비 : 무료 <br/>
            2) 프로그램 수수료 : 거래 금액 총액의 0.2% <br/>
            </QRTitle><br/>
            <Hr/>
            <QRTitle>정회원</QRTitle><br/>

            <QRTitle>
            <QRTitle4>브라더 (brother)</QRTitle4> <br/>
            1) 연회비 : 연 160만 원 (포인트 20만 원 제공) <br/>
            2) 프로그램 수수료 : 수익금의 5%<br/>
            </QRTitle>
            <br/>
            <QRTitle>
            <QRTitle5>패밀리 (family)</QRTitle5> <br/>
            1) 연회비 : 연 160만 원 (포인트 20만 원 제공) <br/>
            2) 프로그램 수수료 : 거래 금액 총액의 0.1% <br/>
            </QRTitle><br/>
            <Hr/>
            <QRTitle3>스페셜 (special)</QRTitle3><br/>
            <QRTitle>
            
            1) 연회비 : 연 380만 원 <br/>
            2) 프로그램 수수료 : 무료<br/>
            </QRTitle>

            <QRTitle>
            약정 <br/>
            회비 결제 후 환불 불가 <br/>
            프로그램 수수료 소진 시 포인트를 충전해야 사용 가능함 <br/>
            ** 충전 금액은 최소 50,000원 이상입니다 ** 
            </QRTitle>
            <Hr/>
            <specialBox>
            <QRTitlespecial>
            위 회원자격은 코인천사 저변확대 보급차원으로 1년 한시적용되며 (2022년12월까지) 이후 조정될수 있습니다
            </QRTitlespecial>
            </specialBox>
        </div>
    )

}
const specialBox = styled.div`
    border : 1px solid white;
    width : 100%;
    padding : 4%;
    box-sizing : border-box;
    text-align :center;
`
const QRTitlespecial = styled.span`
    display : inline-block;
    color : ${colors.textOrenge};
    font-size : 15px;
    font-family : Dream5;
    margin : 5px 0;
    vertical-align : top;
    word-wrap: break-word;
    width : 90%;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 90%;
        word-wrap: break-word;
    }
    }
`
const QRTitle22 = styled.span`
    display : inline-block;
    color : ${colors.Circle};
    font-size : 15px;
    font-family : Dream5;
    margin : 5px 0;
    vertical-align : top;
    word-wrap: break-word;
    width : 90%;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 90%;
        word-wrap: break-word;
    }
    }
`
const QRTitle3 = styled.span`
    display : inline-block;
    color : ${colors.textRed};
    font-size : 15px;
    font-family : Dream5;
    margin : 5px 0;
    vertical-align : top;
    word-wrap: break-word;
    width : 90%;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 90%;
        word-wrap: break-word;
    }
    }
`
const QRTitle4 = styled.span`
    display : inline-block;
    color : ${colors.SkyBlue};
    font-size : 15px;
    font-family : Dream5;
    margin : 5px 0;
    vertical-align : top;
    word-wrap: break-word;
    width : 90%;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 90%;
        word-wrap: break-word;
    }
    }
`
const QRTitle5 = styled.span`
    display : inline-block;
    color : ${colors.textOrenge};
    font-size : 15px;
    font-family : Dream5;
    margin : 5px 0;
    vertical-align : top;
    word-wrap: break-word;
    width : 90%;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 90%;
        word-wrap: break-word;
    }
    }
`
const QRTitle = styled.span`
    display : inline-block;
    color : ${colors.White};
    font-size : 15px;
    font-family : Dream5;
    margin : 5px 0;
    vertical-align : top;
    word-wrap: break-word;
    width : 90%;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 90%;
        word-wrap: break-word;
    }
    }
`

const BoxBlue= styled.span`
    display : block;
    color : ${colors.textOrenge};
    font-size : 26px;
    font-family : Dream6;
    margin-bottom : 20px;;
    @media screen and (max-width: 768px) 
{ & { 
    font-size : 25px;
    width : 100%;
    box-sizing : border-box;
    text-align : center;
    }
}
`
const Hr = styled.div`
    margin : 10px 0;
    widht : 90%;
    border-top: 1px solid ${colors.White};
`


export default MyPageInfo;