import {react,useEffect,useState,useRef} from "react";
import styled from "styled-components"
import back from "../img/backimg.png";
import colors from "./colors.js"
import backMobile from "../img/Mobile2.png";
import backCoin from "../img/coinBack.png";

const Body2=()=>{
    return(
        <div>
        <BodyImgWrapper2/>
        <BodyWrapper>
            <BodyImgWrapper>
            <BigCircle/>
            <MiddleCircle/>
            <ContentWrapper>
                <IndexCircle/>
                <ContentText>거래소와 연동하는 자동매매 플랫폼</ContentText>
                <ContentText3>인간의</ContentText3> 
                <BoxSpanOrenge2>
                    욕심!
                </BoxSpanOrenge2>
                <ContentText3>을 제어한다</ContentText3><br/>
                <ContentText3>일할 때, 잠잘 때도 알아서 척척</ContentText3> 
            </ContentWrapper>
            <MobileImg src={backMobile}/> 
            
            <BoxSpanOrenge3>
                    세력들은 온갖 통계를 가지고 설치는 판
                </BoxSpanOrenge3>
            <Box>
                <BoxSpanWhite>
                    초보자도 쉽게 이용할 수 있는 <br/>
                    아주 간단한 설정 완벽한 최적의 타이밍 매매
                </BoxSpanWhite>
                <BoxSpanOrenge>
                    무료 회원가입으로 인생역전을~
                </BoxSpanOrenge>
            </Box>
            </BodyImgWrapper>
        </BodyWrapper>
        </div>
    );

}
const BodyWrapper = styled.div`
    min-width :1215px;  
    padding : 0 150px;
    display : block;
    width : calc(100% - 300px);
    min-width : 1024px;
    text-align : left;
    overflow : hidden;
    @media screen and (max-width: 768px) 
    { & { 
        padding : 0 40px;
        min-width :0;  
        width : 100%;
        box-sizing : border-box;
        }
    }
`
const BodyImgWrapper = styled.div`
    position : relative; 
    width : 100%;
    height : 100%;
`
const BodyImgWrapper2 = styled.div`
    position : relative; 
    width : 100%;
    height : 130px;
    background-image : url(${back});
    background-size : 100% auto;
    background-position-y :300px;
    @media screen and (max-width: 768px) 
{ & { 
    background-position-y :0px;
    }
`
const MobileImg = styled.img`
    display : block;
    width : 50vw;
    position : absolute; 
    bottom : -120px;
    right : 0px;
    @media screen and (max-width: 768px) 
    { & { 
        width : 50vw;
        bottom : -30px;    
        right : -30px;    
        }
    }
`
const MobileImg2 = styled.img`
    display : block;
    width : 350px;
    position : absolute; 
    top : 0px;
    right : 70%;
    @media screen and (max-width: 768px) 
    { & { 
        position : relative;
        top : 0;
        width : 0;
        }
    }

`
const ContentText = styled.span`
    display : block;
    color : ${colors.textTitle};
    font-size : 37px; 
    bottom : 20px;
    font-family : Dream6;
    z-index : 15;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 17px; 
        }
    }
`
const ContentText2 = styled.span`
    margin-top : 10px;
    display : block;
    color : ${colors.textRed};
    font-size : 23px; 
    bottom : 20px;
    font-family : Dream6;
    z-index : 15;
    @media screen and (max-width: 768px) 
    { & { 
        color : black;
        font-size : 15px; 
        }
    }
`
const ContentText3 = styled.span`
    display : inline-block;
    margin-top : 15px;
    color : red;
    font-size : 28px; 
    bottom : 20px;
    font-family : Dream5;
    z-index : 15;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 13px; 
        margin-top : 10px;
        }
    }
`
const ContentWrapper = styled.div`
    position : relative;
    margin : 0px 20px;
    margin-top :100px;
    display : block;
    @media screen and (max-width: 768px) 
    { & { 
        margin : 30px 0;
        text-align : center;
        }
    }
`
const ContentWrapper2 = styled.div`
    position : relative;
    margin : 100px 20px;
    display : block;
    margin-left : 40%;
    top : 30px;
    width : 60%;
    @media screen and (max-width: 768px) 
    { & { 
        width : 100%;
        margin : 50px 0;
        text-align : center;
        }
    }
`
const BigCircle = styled.div`
    position : absolute;
    background-color : ${colors.backgroundBlue};
    width : 500px;
    height : 500px;
    border-radius : 100%;
    z-index : -1;
    top : -550px;
    left : -250px;
    @media screen and (max-width: 768px) 
    { & { 
        width : 700px;
        height : 700px;
        }
    }
`
const MiddleCircle = styled.div`
    position : absolute;
    background-color : ${colors.backgroundPuple};
    width : 20vw;
    height : 20vw;
    border-radius : 100%;
    z-index : -1;
    bottom : 30vw;
    right : -100px;
    @media screen and (max-width: 768px) 
    { & { 
        width : 37vw;
        height : 37vw;
        bottom : -10vw;
        right : -30vw;
        }
    }
`
const MiddleCircle2 = styled.div`
    position : absolute;
    background-color : ${colors.backgroundSky};
    width : 33vw;
    height : 33vw;
    border-radius : 100%;
    z-index : -1;
    bottom : -350px;
    left : -400px;
    @media screen and (max-width: 768px) 
    { & { 
        width : 37vw;
        height : 37vw;
        bottom : 10vw;
        left : -30vw;
        }
    }
`
const IndexCircle = styled.div`
    position : absolute;
    background-color : ${colors.Circle};
    width : 60px;
    height : 60px;
    border-radius : 100%;
    z-index : -1;
    top : -10px;
    left : -20px;
    @media screen and (max-width: 768px) 
    { & { 
        background-color :rgba(0,0,0,0);
        text-align : center;
        }
    }
`

const Box = styled.div`
    background-color : rgba(49,49,49,0.8);
    width : 880px;
    height : 100%;
    border-radius : 20px;
    margin : 120px 0;
    margin-top : 10px;
    padding : 50px 100px;
    box-sizing : border-box;
    position : relative;
    @media screen and (max-width: 768px) 
    { & { 
        height : 100%;
        position : relative;
        top : -30px;
        width : 100%;
        padding : 30px;
        margin : 30px auto;
        }
    }
`
const BoxSpanWhite = styled.span`
font-size : 30px;
color : ${colors.White};
font-family : Dream6;
display : inline-block;
padding-bottom : 30px;
@media screen and (max-width: 768px) 
{ & { 
    width : 100%;
    font-size : 16px;
    text-align : center;
    padding-bottom : 10px;
    }
}
`
const BoxSpanOrenge = styled.span`
font-size : 25px;
color : ${colors.textOrenge};
font-family : Dream6;
display : block;
@media screen and (max-width: 768px) 
{ & { 
    display : inline-block;
    width : 100%;
    font-size : 18px;
    text-align : center;
    }
}
`
const BoxSpanOrenge2 = styled.span`
font-size : 35px;
color : ${colors.textOrenge};
font-family : Dream6;
display : inline-block;
@media screen and (max-width: 768px) 
{ & { 
    display : inline;
    width : 100%;
    font-size : 18px;
    text-align : center;
    }
}
`
const BoxSpanOrenge3 = styled.span`
font-size : 35px;
color : ${colors.textOrenge};
font-family : Dream6;
display : inline-block;
margin-top : 100px;
@media screen and (max-width: 768px) 
{ & { 
    display : inline;
    width : 100%;
    font-size : 18px;
    text-align : center;
    }
}
`
export default Body2;