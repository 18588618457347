import {react,useEffect,useState,useRef} from "react";
import styled from "styled-components"
import icon1 from "../img/Angelicon.png";
import icon2 from "../img/blockchain.png";
import icon3 from "../img/touch.png";
import icon4 from "../img/bar-chart.png";
import colors from "./colors.js"

const Body3=()=>{
    console.log(window.innerWidth);
    return (
        <Wrapper>
            <Box>
            <ImgIcon2 src={icon1}/>
            <BoxTitle>누구나 이용하기 쉽고 <br/> 편리한 매수 / 매도</BoxTitle>
            <BoxBlue>편리성</BoxBlue>
            <Hr/>
            <UnderHr>간편 로그인, 트레이딩 뷰 차트 지원</UnderHr>
            </Box>
            <Box>
            <ImgIcon src={icon2}/>
            <BoxTitle>거래소 연동을 통한 <br/>다양한 가상화폐 거래소</BoxTitle>
            <BoxBlue>통합관리</BoxBlue>
            <Hr/>
            <UnderHr>국내 가상화폐 거래소 527개의<br/> 거래쌍 빠른 속도로 거래</UnderHr>
            </Box>
            <Box>
            <ImgIcon src={icon3}/>
            <BoxTitle>유일무이! 지금까지 없었던! <br/>이용이 매우 간편한</BoxTitle>
            <BoxBlue>단 5분!</BoxBlue>
            <Hr/>
            <UnderHr>기존의 자동매매 프로그램과 다르게<br/> 이용이 매우 간단하여 쉽게 5분만에 <br/>이용 가능</UnderHr>
            </Box>
            <Box>
            <ImgIcon src={icon4}/>
            <BoxTitle>수면부족에 시달리는 <br/>가상화폐 투자자를 위한<br/></BoxTitle>
            <BoxBlue>수익극대</BoxBlue>
            <Hr/>
            <UnderHr>매수 / 매도 쌍,<br/>stop-loss / trailing-stop <br/>/ trailing-buy</UnderHr>
            </Box>
        </Wrapper>
    )
}
const Wrapper = styled.div`
    min-width :1215px;  
    padding : 70px 120px;
    background-color : ${colors.backBlue};
    @media screen and (max-width: 768px) 
    { & { 
        padding : 20px 40px;
        min-width :0;  
        }
    }
`
const Box = styled.div`
    text-align : left;
    display : inline-block;
    padding : 35px; 
    width : calc(25% - 15px);
    height : 25vw;
    box-sizing : border-box;
    margin-right : 20px;
    background-color : ${colors.White};
    vertical-align : top;
    &:last-child{
        margin-right :0;
    }
    @media screen and (max-width: 768px) 
    { & { 
        width : 46%;
        height : 100%;
        min-height : 44vw;
        padding : 10px 20px;
        margin : 10px 5px;

        }
        &:last-child{
            margin : 10px 5px;
        }
    }
`
const ImgIcon = styled.img`
    width : 4vw;
    height : 4vw;
    display : block;
    @media screen and (max-width: 768px) 
    { & { 
        display : none;
        }
    }
`
const ImgIcon2 = styled.img`
    width : 4vw;
    height : 4vw;
    display : block;
    @media screen and (max-width: 768px) 
    { & { 
        width : 11vw;
        height : 11vw;
        display : none;
        }
    }
`
const BoxTitle = styled.span`
    display : block;
    color : ${colors.textGray};
    font-size : 1.2vw;
    font-family : Dream5;
    margin : 15px 0;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 2.7vw;
        margin : 5px 0;
        }
    }
`
const BoxBlue= styled.span`
    display : block;
    color : ${colors.SkyBlue};
    font-size : 1.5vw;
    font-family : Dream6;
    margin-bottom : 4vw;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 2.5vw;
        }
    }
`
const Hr = styled.div`
    margin : 10px 0;
    widht : 90%;
    border-top: 1px solid ${colors.textGray};
`
const UnderHr= styled.span`
    display : inline-block;
    color : ${colors.textGray};
    font-size : 1vw;
    font-family : Dream4;
    text-align : left;
    margin : 0 auto;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 2.5vw;
        }
    }
`

export default Body3;