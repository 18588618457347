import axios from "axios";
import {server,CheckEmail,CheckNickname,CheckEmailCode,Join} from "./constValue";
export const CheckEmailFunc = (email)=>{
    var postData ={
        "email": email,
      };
    return axios.post(server+CheckEmail,postData).then((res)=>{
        return res.data;
    });      
}
export const CheckEmailCodeFunc = (email,checkCode)=>{
    var postData ={
        "email": email,
        "checkCode" : checkCode,
      };
    return axios.post(server+CheckEmailCode,postData).then((res)=>{
        return res.data;
    });      
}
export const CheckNicknameFunc = (nickname)=>{
    var postData ={
        "nickname": nickname,
      };
    return axios.post(server+CheckNickname,postData).then((res)=>{
       return res.data;
    });      
}
export const JoinFunc = async (email,pw,checkCode,nickname,name,phone,birthday,gender)=>{
    var postData ={
        "manager": "", 
        "nickname":nickname, 
        "email" :email , 
        "checkCode":checkCode,
        "pw" :pw,
        "dlfma" :name,
        "phone" : phone,
        "birthday" : birthday,
        "gender" : gender 
        };
       
    return axios.post(server+Join,postData)
}
export default JoinFunc;