import axios from "axios";
import {server,Deposit} from "./constValue";
 const DepositFunc = async (email,from_address,amount,levelState,HowDeposit,receipt,business_num,business_type)=>{
  const ETHprice = await axios.post(server+"/getEthPrice").then((res)=>{return res});
  var postData;
  if(HowDeposit===true){
    postData = {
      "email": email,
      "from_address": from_address,
      "to_address": "0xca3B83433D3Ae521B3e1b6884bf8f0Ab078131c5",
      "amount": amount,
      "price" : ETHprice.data,
      "total_point": ETHprice.data*amount,
      "offer_type" : levelState,
      "deposit_type" : "coin",
      "cash_receipts": receipt,
      "business_num" : "null",
      "business_type" : "null"
    };
  }
  else{
    postData = {
      "email": email,
      "from_address": from_address,
      "to_address": "351-1200-5819-03 농협 백랑기",
      "amount": amount,
      "price" : ETHprice.data,
      "total_point": amount,
      "offer_type" : levelState,
      "deposit_type" : "krw",
      "cash_receipts": receipt,
      "business_num" : business_num,
      "business_type" : business_type
    };
  }
  axios.post(server+Deposit,postData)
}
export default DepositFunc;