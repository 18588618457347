import React, {  useMemo, useState } from 'react';
import { createContext } from "react";

export const LoginInfo = createContext({Info:{}});
const LoginInfoContext = (props)=>{
    const [Info, setInfo] = useState({});
    const SetInfoUser = (Info)=>{
        setInfo({Info : Info})
    }
    return (
        <LoginInfo.Provider value = {{Info : Info, dispatch : SetInfoUser}}>
            {props.children}
        </LoginInfo.Provider>
    )

}
export default LoginInfoContext;