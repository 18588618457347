import {react,useEffect,useState,useRef, useContext} from "react";
import styled from "styled-components"
import colors from "./colors.js"
import Header from "./Header.js";
import { useHistory, useLocation } from "react-router";
import DepositFunc from "../hook/Deposit.js";
import Modal from "./Modal.js";
import QRimg from "../img/addressQR.png"
import back from "../img/backimg.png";
import {LoginInfo} from "../contextAPI/LoginInfo.js";
import QRBank from "../img/QRNongBank.PNG";
import axios from "axios";
import { server } from "../hook/constValue.js";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ChangeModal from "./ChangeModal.js";
import MyPageInfo from "./MyPageInfo.js";
import Point from "./MyPagePoint.js";
import Brother from "./MyPageBrother.js";
import Family from "./MyPageFamily.js";
import Special from "./MyPageSpacial.js";
import GetMyInfoFunc from "../hook/GetMyInfo.js";
import NickChangeModal from "./NickChangeModal.js";
import PhoneChangeModal from "./PhoneChangeModal.js";
import PwChangeModal from "./PwChangeModal.js";
const MyPage=()=>{
    const [HowDeposit, setHowDeposit] = useState(true); // ture ETH false KRW 
    const BoxRef = useRef();
    const BodyRef = useRef();
    const [Height,setHeight] = useState();
    const [Info, setInfo]= useState();
    const [WindowHeight,SetWindowHeight] = useState() ;
    const [exDateInfo, SetExDateInfo] = useState();
    const History = useHistory();
    const [ChangeState,setChangeState] = useState(true);
    const [changeModal,SetChangeModal]=useState(false);
    const [NickChangeModalState,SetNickChangeModalState]=useState(false);
    const [PhoneChangeModalState,SetPhoneChangeModalState]=useState(false);
    const [PwChangeModalState,SetPwChangeModalState]=useState(false);

    useEffect(()=>{
        const InfoString = sessionStorage.getItem("LoginInfo");
        const InfoValue = JSON.parse(InfoString);
        GetMyInfoFunc(InfoValue.email).then(res=>{
            setInfo(res);
        })
        if(InfoValue==null){
            alert("로그인이 필요합니다.");
            History.push("/Login")
        }
    },[])
    useState(()=>{
        if(Info!=undefined){
            let date = new Date(Info.expiration);
            var year = date.getFullYear();
            var month = ('0' + (date.getMonth() + 1)).slice(-2);
            var day = ('0' + date.getDate()).slice(-2);
            var dateString = year + '-' + month  + '-' + day;
            SetExDateInfo(dateString);
        }
    },[Info])
    useEffect(()=>{
        try{
            setHeight(BoxRef.current.offsetHeight);
            SetWindowHeight(BodyRef.current.offsetHeight+BoxRef.current.offsetHeight);
        }
        catch{
            setHeight("100%");
            SetWindowHeight("100%");
        }
    })
    useEffect(()=>{
        if(Info!=undefined){
        if(Info.alias=="members"||Info.alias=="friend"){
            if(Info.point<7000){
                console.log(Info.point)
                setChangeState(true);
            }
            else{
                setChangeState(false);
            }
        }
        }
    })
    if(Info !==undefined){
        return(
            <Wrap>
            <Header />
            <Wrapper ref={BodyRef}>
                <Box ref={BoxRef} H={"100%"}>
                <BoxBlue>내 정보</BoxBlue>
                <BoxTitle>이메일 : </BoxTitle>
                <BoxContent>{Info.email}</BoxContent>
                <br/>
                <BoxTitle>닉네임 : </BoxTitle>
                <BoxContent>{Info.nickname}</BoxContent><Changebutton onClick={()=>{SetNickChangeModalState(true)}} State={true}>변경</Changebutton>
                <br/>
                <BoxTitle>전화번호 : </BoxTitle>
                <BoxContent>{Info.phone}</BoxContent><Changebutton onClick={()=>{SetPhoneChangeModalState(true)}} State={true}>변경</Changebutton>
                <br/>
                <BoxTitle>비밀번호 : </BoxTitle>
                <Changebutton onClick={()=>{SetPwChangeModalState(true)}} State={true} style={{margin:0,width : 100}}>비밀번호 변경</Changebutton>
                <br/>

                <BoxTitle>보유 포인트 : </BoxTitle>
                <BoxContent>{Info.point}</BoxContent>
                <br/>
                <BoxTitle>등급 : </BoxTitle>
                <BoxContent>{(Info.alias=="event1" || Info.alias=="event3" || Info.alias=="event60")?"VIP":"mate"}</BoxContent>
                {
                    (Info.alias=="members"||Info.alias=="friend")?<Changebutton onClick={()=>{SetChangeModal(true)}} State={ChangeState} disabled={!ChangeState}>변경</Changebutton>:<span/>
                }
                <br/>
                <Hr/>
		{/*<MyPageInfo/>*/}
                <Hr/>
                <BoxBlue>입금 방식</BoxBlue>
                <BoxContent>1) 계좌 입금 : <br/>농협 (예금주 백랑기 코인엔젤) 351-1200-5819-03 <br/><br/>
                2) 전자지갑 : 0xca3B83433D3Ae521B3e1b6884bf8f0Ab078131c5
                <CopyToClipboard text="0xca3B83433D3Ae521B3e1b6884bf8f0Ab078131c5">
                <BoxContent4 onClick={()=>{alert("주소가 복사되었습니다.")}}>복사</BoxContent4>
                </CopyToClipboard>
                </BoxContent>
                <ButtonBox3 onClick={()=>{setHowDeposit(false)}}>계좌 입금</ButtonBox3>
                <ButtonBox3 onClick={()=>{setHowDeposit(true)}}>전자지갑 입금</ButtonBox3>
                </Box>
                <Box  H={Height} >
                <BoxBlue>포인트 충전</BoxBlue>
                <Point email={Info.email} HowDeposit={HowDeposit} alias={Info.alias}/>
                </Box>
                <Box  H={Height} >
	{/*
                //<BoxBlue>브라더 가입</BoxBlue>
                //{Info.alias==="brothers"?<div><BoxContent2>이미 브라더에 가입하셨습니다.</BoxContent2><br/><BoxContent2>멤버쉽이 {exDateInfo} 에 만료됩니다.</BoxContent2></div>:<Brother email={Info.email} HowDeposit={HowDeposit}/>}
                //</Box>
                //<Box  H={Height} >
                //<BoxBlue>패밀리 가입</BoxBlue>
                //{Info.alias==="famliy"?<div><BoxContent2>이미 패밀리에 가입하셨습니다.</BoxContent2><br/><BoxContent2>멤버쉽이 {exDateInfo} 에 만료됩니다.</BoxContent2></div>:<Family email={Info.email} HowDeposit={HowDeposit}/>}
                //</Box>
                //<Box  H={Height} >
		*/}
                <BoxBlue>VIP 가입</BoxBlue>
                {Info.alias==="event1" || Info.alias==="event3" || Info.alias==="event60"?<div><BoxContent2>이미 VIP에 가입하셨습니다.</BoxContent2><br/><BoxContent2>멤버쉽이 {exDateInfo} 에 만료됩니다.</BoxContent2></div>:<Special email={Info.email} HowDeposit={HowDeposit}/>}
                </Box>
                <WrapperColor Height={WindowHeight}/>
            </Wrapper>
            {changeModal?<ChangeModal email={Info.email} SetModalState={SetChangeModal}/>:null}
            {NickChangeModalState?<NickChangeModal email={Info.email} SetModalState={SetNickChangeModalState}/>:null}
            {PhoneChangeModalState?<PhoneChangeModal email={Info.email} SetModalState={SetPhoneChangeModalState}/>:null}
            {PwChangeModalState?<PwChangeModal email={Info.email} SetModalState={SetPwChangeModalState}/>:null}

            </Wrap>
        )
    }
    else{
        return (<div>Loading</div>)
    }
}
const Wrap = styled.div`
    overflow : hidden;
    position : relative;
`
const Changebutton = styled.button`
    width : 40px;
    padding : 5px;
    font-size : 10px;
    font-family : Dream4;
    border-radius : 10px;
    background-color : ${
        props=>{
            if(props.State==true){
                return colors.SkyBlue;
            }
            else {
                return "gray";
            }
        }};
    border : none;
    color : white;
    margin-left : 10px;
`
const FlexDiv = styled.div`
    display : flex-box;
    flex-direction : row;
    flex-wrap: nowrap;
    align-items : center;
    justify-content : center;
`
const InputCheckBox = styled.input`
    height : 15px;
    border : 1px solid black;
    width : 15px;
    position : relative;
    top : 5px;
    vertical-align : top;
    @media screen and (max-width: 768px) 
    {
        top : 3px;
    }
`
const InputBox= styled.input`
    width :calc(100% - 220px);
    height : 30px;
    padding : 5px 15px;
    vertical-align : middle;
    border : none;
    margin : 10px ;
    font-family : Dream4;
    border-bottom : 1px solid black;
    border-radius : 20px;
    @media screen and (max-width: 768px) 
{ & { 
    min-width :0;  
    width : 100%;
    box-sizing : border-box;
    text-align : left;
    margin : 20px 0;
    }
}
`

const Box = styled.div`
text-align : left;
display : inline-block;
padding : 40px 60px ; 
width : calc(50% - 30px);
margin : 15px;
box-sizing : border-box;
z-index : 10;
min-height : 100%;
height : ${props=>{
    if(props.H==undefined){
        return "100%";
    }
    else{
        return props.H+"px"
    }
    }};
background-color : rgba(49,49,49,1);
border-radius : 20px;
vertical-align : top;
&:last-child{
    margin-right :0;
}
position : relative;
@media screen and (max-width: 768px) 
{ & { 
    padding : 40px;
    min-width :0;  
    width : 100%;
    height : 100%;
    box-sizing : border-box;
    text-align : left;
    margin : 20px 0;
    }
}
`
const QRImgDiv = styled.div`
    width : 100%;
    text-align : left;
    @media screen and (max-width: 768px) 
{ & { 
    min-width :0;  
    width : 100%;
    box-sizing : border-box;
    margin : 20px 0;
    }
}
`
const WrapperColor = styled.div`
background-color: rgba(0, 59, 119,0.8);
position : absolute;
top : 0;
width : 100%;
left : 0px;
height : ${props=>{return props.Height}}px;
z-index : 1;
`
const Wrapper = styled.div`
padding : 140px 150px;
display : block;
width : calc(100% - 300px);
min-width :1215px;  
height: 100%;
background-image : url(${back});
background-size : auto 100%;
text-align : left;
overflow : hidden;
@media screen and (max-width: 768px) 
{ & { 
    padding : 110px 40px;
    min-width :0;  
    width : 100%;
    height: 100%;
    background-size : auto 100%;
    background-position-x : -120px;
    box-sizing : border-box;
    text-align : center;
    }
}
`
const ButtonBox = styled.button`
    height : 60px; 
    width : 240px;
    cursor:pointer;
    border-radius : 40px;
    background-color : ${props=>{
        if(props.State){
            return colors.SkyBlue
        }
        else{
            return "gray"
        }
    }};
    font-family : Dream5;
    font-size : 24px;
    border : none;
    text-align : center;
    color : white;
    display : block;
    margin :20px auto;
    box-shadow: 3px 2px 5px rgba(0, 0, 0,0.2);
    `
    const ButtonBox2 = styled.button`
    height : 60px; 
    width : 240px;
    cursor:pointer;
    border-radius : 40px;
    background-color : ${colors.SkyBlue};
    font-family : Dream5;
    font-size : 24px;
    border : none;
    text-align : center;
    color : white;
    display : block;
    margin :20px auto;
    box-shadow: 3px 2px 5px rgba(0, 0, 0,0.2);
    `
    const ButtonBox3 = styled.button`
    height : 60px; 
    width : 40%;
    cursor:pointer;
    border-radius : 40px;
    background-color : ${colors.SkyBlue};
    font-family : Dream5;
    font-size : 24px;
    border : none;
    text-align : center;
    color : white;
    display : inline-block;
    margin :20px;
    box-shadow: 3px 2px 5px rgba(0, 0, 0,0.2);
    @media screen and (max-width: 768px) 
{ & { 
    width : 45%;
    margin : 5px;
    font-size : 18px;
    }
}
    `
const ImgIcon = styled.img`
    width : 150px;
    height : 150px;
    display : inline-block;
    margin : 20px 0 ;
    position : relative;
    left : calc(100% - 220px);
    @media screen and (max-width: 768px) 
    { & { 
        margin : 20px auto;
        margin-top : 50px;
        left : calc(100% - 210px);
            }
    }
`
const QRTitle2 = styled.span`
    display : inline-block;
    color : ${colors.White};
    font-size : 15px;
    font-family : Dream5;
    margin : 5px 0;
    vertical-align : top;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        
        word-wrap: break-word;
    }
    }
`
const QRTitle22 = styled.span`
    display : inline-block;
    color : ${colors.Circle};
    font-size : 15px;
    font-family : Dream5;
    margin : 5px 0;
    vertical-align : top;
    word-wrap: break-word;
    width : 90%;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 90%;
        word-wrap: break-word;
    }
    }
`
const QRTitle3 = styled.span`
    display : inline-block;
    color : ${colors.textRed};
    font-size : 15px;
    font-family : Dream5;
    margin : 5px 0;
    vertical-align : top;
    word-wrap: break-word;
    width : 90%;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 90%;
        word-wrap: break-word;
    }
    }
`
const QRTitle4 = styled.span`
    display : inline-block;
    color : ${colors.SkyBlue};
    font-size : 15px;
    font-family : Dream5;
    margin : 5px 0;
    vertical-align : top;
    word-wrap: break-word;
    width : 90%;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 90%;
        word-wrap: break-word;
    }
    }
`
const QRTitle5 = styled.span`
    display : inline-block;
    color : ${colors.textOrenge};
    font-size : 15px;
    font-family : Dream5;
    margin : 5px 0;
    vertical-align : top;
    word-wrap: break-word;
    width : 90%;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 90%;
        word-wrap: break-word;
    }
    }
`
const QRTitle = styled.span`
    display : inline-block;
    color : ${colors.White};
    font-size : 15px;
    font-family : Dream5;
    margin : 5px 0;
    vertical-align : top;
    word-wrap: break-word;
    width : 90%;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 90%;
        word-wrap: break-word;
    }
    }
`
const QRInputSpan = styled.span`
    display : inline-block;
    color : ${colors.White};
    font-size : 15px;
    font-family : Dream5;
    margin : 15px 0;
    vertical-align : top;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 15px;
        display : inline-block ; 
        box-sizing : border-box;
        text-align : left;
        width : 100%;
        word-wrap: break-word;
    }
    }
`
const BoxTitle = styled.span`
    display : inline-block;
    color : ${colors.White};
    font-size : 15px;
    font-family : Dream5;
    width : 120px;
    vertical-align : middle;
    text-align : left;
    @media screen and (max-width: 768px) 
{ & { 
    font-size : 15px;
    display : inline ; 
    box-sizing : border-box;
    text-align : left;
    margin : 10px 0;
    }
}
`
const BoxTitle2 = styled.span`
    display : inline-block;
    color : ${colors.White};
    font-size : 15px;
    font-family : Dream5;
    margin : 15px 0;
    width : 160px;
    vertical-align : middle;
    text-align : left;
    @media screen and (max-width: 768px) 
{ & { 
    font-size : 15px;
    display : inline-block;
    padding : 0; 
    margin : 0;

    }
}
`
const BoxContent = styled.span`
display : inline-block;
color : ${colors.White};
font-size : 15px;
font-family : Dream5;
margin : 15px 0;
vertical-align : middle;
@media screen and (max-width: 768px) 
{ & { 
    font-size : 15px;
    display : inline-block;
    padding : 15px 0; 
    margin : 0;
    word-break:break-all;
    box-sizing : border-box;
    text-align : left;
    }
}
`
const BoxContent4 = styled.span`
display : inline-block;
color : ${colors.White};
font-size : 15px;
font-family : Dream5;
margin : 0px 3px;
top : -3px;
position : relative;
padding : 3px;
vertical-align : middle;
border : 1px solid white;
@media screen and (max-width: 768px) 
{ & { 
    font-size : 15px;
    display : inline-block; 
    margin : 3px ;
    box-sizing : border-box;
    text-align : left;
    }
}
`
const BoxContent2 = styled.span`
display : inline-block;
color : ${colors.White};
font-size : 15px;
font-family : Dream5;
margin : 15px 0;
vertical-align : middle;
@media screen and (max-width: 768px) 
{ & { 
    font-size : 15px;
    display : inline-block;
    padding : 5px 0; 
    margin : 0;
    box-sizing : border-box;
    text-align : left;
    }
}
`
const BoxBlue= styled.span`
    display : block;
    color : ${colors.textOrenge};
    font-size : 26px;
    font-family : Dream6;
    margin-bottom : 20px;;
    @media screen and (max-width: 768px) 
{ & { 
    font-size : 25px;
    width : 100%;
    box-sizing : border-box;
    text-align : center;
    }
}
`
const Hr = styled.div`
    margin : 10px 0;
    widht : 90%;
    border-top: 1px solid ${colors.White};
`


export default MyPage;
