import axios from "axios";
import {server,Login} from "./constValue";
import GetMyInfoFunc from "./GetMyInfo"; 
const LoginFunc = (email,pw,History)=>{
    var postData = {
        "email": email,
        "pw": pw,
      };
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.post(server+Login,postData).then(async (res)=>{
        console.log(postData)
        if(res.data === true){
            const Information = await GetMyInfoFunc(email,pw);
            sessionStorage.setItem("LoginInfo",JSON.stringify(Information))
            History.push({pathname : "/MyPage"})
        }
        else{
            console.log(res);
            alert("아이디와 비밀번호를 확인해주세요.")
        }
    });
}
export default LoginFunc