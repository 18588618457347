import {react,useEffect,useState,useRef,useContext} from "react";
import styled from "styled-components"
import back from "../img/backimg.png";
import GetPost from "../hook/GetPost";
import Header from "./Header";
import colors from "./colors";
import { useHistory } from "react-router";
import Footer from "./Footer"
import icon_call from "../img/icon_call.png"
import icon_email from "../img/icon_email.png";
const Manual2=()=>{
    return(
        <div>
        <BodyImgWrapper>
        <Header/>
        </BodyImgWrapper>
        <BodyWrapper>
                <Title>프로그램 이용약관 </Title>
                <ContentWrapper>
                <SpanDiv>
                    <Title>제1조 목적</Title>
                    <SpanNum>본 이용약관은 코인엔젤 (Coin Angel) “애플리케이션”과 “사이트”(이하 “애플리케이션”과 “사이트”를 “APP”이라고 함)의 서비스의 이용조건과 운영에 관한 제반사항 규정을 목적으로 합니다. 서비스에 접속하거나 이용함으로써 이용자는 우리의 이용자로 등록되었는지 여부에 관계없이 이용자가 본 이용약관을 읽었고 이해하였으며 적용 받는 것에 동의하게 됩니다.
</SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>제2조 용어의 정의</Title>
                    <SpanNum> 본 약관에서 사용되는 주요한 용어의 정의는 다음과 같습니다.        <br/>
<br/>
① “서비스”란 구현되는 단말기(PC, 휴대형 단말기 등의 각종 유무선 장치를 포함)와 상관없이 회원이 APP에서 이용할 수 있는 서비스를 의미합니다.
<br/>
① “이용자”란 APP에 접속하여 이 약관에 따라 APP에서 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
<br/>
② 회원 : 회사의 서비스에 접속하여 이 약관에 따라 회사와 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 고객을 말합니다.
<br/>
③ “비회원”이란 회원에 가입하지 않고 APP이 제공하는 서비스를 이용하는 자를 의미합니다.
<br/>
④ 이용계약 : APP 이용과 관련하여 APP과 회원간에 체결 하는 계약을 말합니다.
<br/>
⑤ 회원 아이디(이하 “ID”) : 회원의 식별과 회원의 서비스 이용을 위하여 회원별로 부여하는 고유한 문자와 숫자의 조합을 말합니다.
<br/>
⑥ 비밀번호 : 회원의 본인 확인과 비밀 보호를 위해 회원 자신이 설정한 문자, 숫자 또는 양자의 조합을 말합니다.
<br/>
⑦ 운영자 : 서비스에 APP을 개설하여 운영하는 운영자를 말합니다.
<br/>
⑧ 해지 : 회원이 이용계약을 해약하는 것을 말합니다.
</SpanNum>
                </SpanDiv>
                </ContentWrapper>

                <ContentWrapper>
                <SpanDiv>
                    <Title>제3조 약관 외 준칙

</Title>
                    <SpanNum> 운영자는 필요한 경우 별도로 운영정책을 공지 안내할 수 있으며, 본 약관과 운영정책이 중첩될 경우 운영정책이 우선 적용됩니다.

</SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>제4조 이용계약 체결
</Title>
                    <SpanNum> 
                    <br/>
                    ① 이용계약은 회원으로 등록하여 APP을 이용하려는 자의 본 약관 내용에 대한 동의와 가입신청에 대하여 운영자의 이용승낙으로 성립합니다.
                    <br/>
② 회원으로 등록하여 서비스를 이용하려는 자는 APP 가입신청 시 본 약관을 읽고 회원가입을 하는 것으로 본 약관에 대한 동의 의사 표시를 합니다.</SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>제5조 회원 가입
</Title>
                    <SpanNum> 
                    ① 이용자는 무료로 회원에 가입할 수 있으며 회사가 정한 가입 양식에 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
                    <br/>
                    ② 온라인 가입신청 양식에 기재하는 모든 회원 정보는 실제 데이터인 것으로 간주하며 실제 정보를 입력하지 않은 사용자는 법적인 보호를 받을 수 없으며 관계 법령에 따라 처벌 받을 수 있습니다.
<br/>
③ 이용 계약은 이용자의 이용 신청에 대하여 회사가 승낙함으로써 성립합니다. 다만 회사는 다음 각 호에 해당하는 신청에 대하여 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
<br/>        <br/>
1. 가입 신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우 단 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 함.<br/>
2. 허위의 정보를 기재하거나 회사가 제시하는 내용을 기재하지 않은 경우<br/>
3. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우<br/>
<br/>
④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.</SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>제6조 회사의 의무
</Title>
                    <SpanNum> 
                    ① 회사는 이용회원으로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 가급적 빨리 처리하여야 합니다. 다만, 개인적인 사정으로 신속한 처리가 곤란한 경우에는 사후에 공지 또는 이용회원에게 전화연락, 전자우편 등을 보내는 등 최선을 다합니다.
                    <br/>
                    ② 회사는 계속적이고 안정적인 APP 제공을 위하여 설비에 장애가 생기거나 유실된 때에는 이를 지체 없이 수리 또는 복구할 수 있도록 APP에 요구할 수 있습니다. 다만, 천재지변 또는 APP이나 운영자에 부득이한 사유가 있는 경우, APP 운영을 일시 정지할 수 있습니다.
<br/>
③ 회사는 회원의 귀책사유로 인한 서비스 이용의 장애 및 통신 장애 등으로 인한 서비스 이용 장애 등에 대하여는 책임을 지지 않습니다.
</SpanNum><br/>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>제7조 회원의 의무


</Title>
                    <SpanNum> 
                    ① 회원은 본 약관에서 규정하는 사항과 운영자가 정한 제반규정, 공지사항 및 운영정책 등 APP이 공지하는 사항 및 관계법령을 준수하여야 하며, 기타 APP의 업무에 방해가 되는 행위, APP의 명예를 손상시키는 행위를 해서는 안됩니다.
                    <br/>
                    ② 회원은 APP의 명시적 동의가 없는 한 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
<br/>
③ 이용고객은 아이디 및 비밀번호 관리에 상당한 주의를 기울여야 하며, 운영자나 APP의 동의 없이 제3자에게 아이디를 제공하여 이용하게 할 수 없습니다.
<br/>
④ 회원은 운영자와 APP 및 제3자의 지적 재산권을 침해해서는 안됩니다.</SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>제8조 서비스 이용시간
</Title>
                    <SpanNum> 
                    
                    ① 서비스 이용시간은 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 단, APP은 시스템 정기점검, 증설 및 교체를 위해 APP이 정한 날이나 시간에 서비스를 일시 중단 할 수 있으며 예정된 작업으로 인한 서비스 일시 중단은 APP의 홈페이지에 사전에 공지하오니 수시로 참고하시길 바랍니다.                    <br/>
② 단, APP은 다음 경우에 대하여 사전 공지나 예고 없이 서비스를 일시적 혹은 영구적으로 중단할 수 있습니다.
<br/>        <br/>
- 긴급한 시스템 점검, 증설, 교체, 고장 혹은 오동작을 일으키는 경우<br/>
- 국가비상사태, 정전, 천재지변 등의 불가항력적인 사유가 있는 경우<br/>
- 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지한 경우<br/>
- 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우<br/>
<br/>
③ 전항에 의한 서비스 중단의 경우 APP은 사전에 공지사항 등을 통하여 회원에게 통지 합니다. 단, APP이 통제할 수 없는 사유로 발생한 서비스의 중단에 대하여 사전공지가 불가능한 경우에는 사후공지로 대신합니다.

</SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>제9조 회원 탈퇴 및 자격 상실 등
</Title>
                    <SpanNum> 
                    ① 회원은 언제든지 회원탈퇴 이용계약 해지 신청을 할 수 있으며 회사는 관련 법이 정하는 바에 따라 이를 처리합니다.<br/>
            
② 다음의 사항에 해당하는 경우 회사는 사전 동의 없이 가입해지나 서비스 중지 조치를 취할 수 있습니다.
<br/>        <br/>
1. 본 서비스의 목적에 맞지 않게 서비스를 이용하여 사회적 물의가 발생한 때.<br/>
2. 가입 신청 시에 허위 내용을 등록한 경우<br/>
3. 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우<br/>
4. 회사를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우<br/>
5. 기타 본 서비스 명예를 훼손하였을 때.<br/>
6. 본 약관을 포함하여 기타 회사가 정한 이용 조건에 위반한 경우<br/>

</SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>제10조 서비스 이용 제한

</Title>
                    <SpanNum> 
                    회원은 다음 각 호에 해당하는 행위를 하여서는 아니되며 해당 행위를 한 경우에 APP은 회원의 서비스 이용 제한 및 적법한 조치를 취할 수 있으며 이용계약을 해지하거나 기간을 정하여 서비스를 중지할 수 있습니다.
                    <br/><br/>
① 회원 가입 시 혹은 가입 후 정보 변경 시 허위 내용을 등록하는 행위
<br/>
② 타인의 APP 이용을 방해하거나 정보를 도용하는 행위
<br/>
③ APP의 운영진, 직원 또는 관계자를 사칭하는 행위
<br/>
④ APP, 기타 제3자의 인격권 또는 지적재산권을 침해하거나 업무를 방해하는 행위
<br/>
⑤ 다른 회원의 ID를 부정하게 사용하는 행위
<br/>
⑥ 다른 회원에 대한 개인정보를 그 동의 없이 수집, 저장, 공개하는 행위
<br/>
⑦ 범죄와 결부된다고 객관적으로 판단되는 행위
<br/>
⑧ 기타 관련 법령에 위배되는 행위
<br/>


</SpanNum>
                </SpanDiv>
                </ContentWrapper>

                <ContentWrapper>
                <SpanDiv>
                    <Title>
제11조 게시물의 관리
</Title>
                    <SpanNum> 
                    
① 게시물이라 함은 인터넷사이트에 회원이 올린 이미지, 글, 동영상 또는 음성 파일 등을 포함합니다.
<br/>
② 회원이 게시하는 정보(모든 게시물 포함) 등으로 인해 발생하는 손실이나 문제는 전적으로 회원 개인의 판단에 따른 책임이며, 회사의 고의 또는 중과실이 아닌 한 회사는 이에 대하여 책임지지 않습니다.
<br/>
③ 회원은 공공질서나 미풍양속에 위배되는 내용과 타인의 저작권을 포함한 지적재산권 및 기타 권리를 침해하는 내용물에 대하여는 등록, 게시, 전송할 수 없으며 만일 이와 같은 내용의 게시물로 인해 발생하는 결과에 대한 모든 책임은 회원 본인에게 있습니다.
<br/>
④ 회사는 회원이 게재 또는 등록하는 내용물이 다음 각호에 해당된다고 판단하는 경우에 회원의 동의 없이 그 내용을 삭제할 수 있습니다.
<br/><br/>
1. 다른 회원 또는 제 3자에게 심한 모욕을 주거나 명예를 손상시키는 내용인 경우<br/>
2. 공공질서 및 미풍양속에 위반되는 저속, 음란한 내용의 정보, 문장, 도형, 음향, 동영상을 전송, 게시하거나 링크시키는 경우<br/>
3. 사적인 정치적 판단이나, 종교적 견해의 내용으로 회사가 서비스 성격에 부합하지 않는다고 판단하는 경우<br/>
4. 범죄와 결부된다고 판단되는 내용일 경우<br/>
5. 다른 회원 또는 제3자의 저작권 등 기타 권리를 침해하는 내용일 경우<br/>
6. 기타 관계 법령이나 회사에서 정한 규정에 위배된다고 판단되는 경우<br/>
<br/>
⑤ 정보통신윤리위원회 등 공공기관의 시정요구가 있는 경우 운영자는 회원의 사전동의 없이 게시물을 삭제하거나 이동 할 수 있습니다.<br/>

</SpanNum>
                </SpanDiv>
                </ContentWrapper>

                <ContentWrapper>
                <SpanDiv>
                    <Title>제12조 게시물에 대한 저작권
</Title>
                    <SpanNum> 
                    ① 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.<br/>

② 회원이 서비스 내에 게시한 게시물의 저작권은 해당 게시물의 저작자에게 귀속됩니다<br/>

③ 회원이 서비스 내에 게시하는 게시물은 검색결과 내지 서비스 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우 회사는 저작권법 규정을 준수하며 회원은 언제든지 고객센터 또는 서비스 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있습니다.
<br/>
④ 회사는 제3항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우에는 전자우편 등을 통해 사전에 회원의 동의를 얻어야 합니다.<br/></SpanNum>
                </SpanDiv>
                </ContentWrapper>
 
                <ContentWrapper>
                <SpanDiv>
                    <Title>제13조 손해배상
</Title>
                    <SpanNum> 
                    
① 본 APP에서 발생한 모든 민,형법상 책임은 회원 본인에게 1차적으로 있습니다.<br/>

② 본 APP으로부터 회원이 받은 손해가 천재지변 등 불가항력적이거나 회원의 고의 또는 과실로 인하여 발생한 때에 회사는 손해배상을 하지 하지 않습니다.</SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>제14조 면책
</Title>
                    <SpanNum> 
                    ① 운영자는 회원이 APP의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스 자료에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다.
                    <br/>
② 운영자는 본 APP의 서비스 기반 및 타 통신업자가 제공하는 전기통신서비스의 장애로 인한 경우에는 책임이 면제되며 본 APP의 서비스 기반과 관련되어 발생한 손해에 대해서는 APP의 이용약관에 준합니다
<br/>
③ 운영자는 회원이 저장, 게시 또는 전송한 자료와 관련하여 일체의 책임을 지지 않습니다.
<br/>
④ 운영자는 회원의 귀책사유로 인하여 서비스 이용의 장애가 발생한 경우에는 책임지지 아니합니다.
<br/>
⑤ 운영자는 회원 상호간 또는 회원과 제3자 상호간, 기타 회원의 본 서비스 내외를 불문한 일체의 활동(데이터 전송, 기타 커뮤니티 활동 포함)에 대하여 책임을 지지 않습니다.
<br/>
⑥ 운영자는 회원이 게시 또는 전송한 자료 및 본 APP으로 회원이 제공받을 수 있는 모든 자료들의 진위, 신뢰도, 정확성 등 그 내용에 대해서는 책임지지 아니합니다.
<br/>
⑦ 운영자는 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 물품거래 등을 한 경우에 그로부터 발생하는 일체의 손해에 대하여 책임지지 아니합니다.
<br/>
⑧ 운영자는 코인엔젤의 귀책사유 없이 회원간 또는 회원과 제3자간에 발생한 일체의 분쟁에 대하여 책임지지 아니합니다.<br/>
⑨ 운영자는 서버 등 설비의 관리, 점검, 보수, 교체 과정 또는 소프트웨어의 운용 과정에서 고의 또는 고의에 준하는 중대한 과실 없이 발생할 수 있는 시스템의 장애, 개발사가 미처 생각하지 못한 버그 등에 의한 장애, 제3자의 공격으로 인한 시스템의 장애, 국내외의 저명한 연구기관이나 보안관련 업체에 의해 대응방법이 개발되지 아니한 컴퓨터 바이러스 등의 유포나 기타 운영자가 통제할 수 없는 불가항력적 사유로 인한 회원의 손해에 대하여 책임지지 않습니다.<br/>
                    </SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>제 15 조 환불 정책

</Title>
                    <SpanNum> 

                    ① 유료회원은 회비를 납부하고 이용하는 정회원 및 스페셜 회원이 있습니다. 이 경우 회비는 환불되지 않습니다. 
                    <br/>
                    ② 일반회원은 회비는 내지 않지만 서비스를 위하여 포인트를 충전하여 사용해야 합니다. 포인트의 경우 환불은 소비자보호법을 준용합니다. 다만, 유료회원의 회비에 포함된 포인트나 무상 제공된 포인트는 제외됩니다. 
<br/>
③ ②항에 따라 환불 가능한 포인터는 서버 운영비 10만 포인트(10만원 상당) 및 세금을 공제하고 환불됩니다. 
<br/>
</SpanNum>
                </SpanDiv>
                </ContentWrapper>
                <ContentWrapper>
                <SpanDiv>
                    <Title>제 16 조 분쟁의 해결
</Title>
                    <SpanNum> 

                    ① 회사와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.
                    <br/>
② 회사와 회원간에 제기된 소송은 대한민국법을 준거법으로 합니다.
<br/>
③ 회사와 회원간 발생한 분쟁에 관한 소송은 대한민국 민사소송법상 규정에 따라 관할법원을 정합니다.
<br/>
④ 전항에도 불구하고 해외에 주소나 거소가 있는 회원의 경우 회사와 회원간에 발생한 분쟁에 관한 소송은 대한민국 서울중앙지방법원을 관할법원으로 합니다.                    </SpanNum>
                </SpanDiv>
                </ContentWrapper>

        </BodyWrapper>
        <Footer/>
        </div>
    );

}
const Title = styled.span`
    display : block;
    font-size : 30px;
    font-family : Dream5;
    padding : 20px 0;
    width : 100%;
    @media screen and (max-width: 768px) 
{ & { 
    min-width : 0;
    padding :10px 0;
    }
`
const Content = styled.span`
display : block;
font-size : 20px;
font-family : Dream3;
margin : 2% auto;
padding : 20px 0;
text-align : left;
padding-bottom : 100px;
color : ${colors.textGray};
border-bottom : 2px solid ${colors.backBlue};
@media screen and (max-width: 768px) 
{ & { 
    margin-bottom : 80px;    
}
`

const Icon = styled.img`
    width : 40px; 
    height : 40px; 
    margin-right : 40px;
`
const SpanDiv = styled.div`
    display : inline-block;
    
`
const SpanNum = styled.span`
    font-size : 20px;
    color : ${colors.textGray};
    text-align : left;
    line-height : 37px;
`
const ContentWrapper = styled.div`

    width : 90%;
    justify-content : center;
    align-items : center;
    margin : 30px; 
    text-align : left;
    @media screen and (max-width: 768px) 
    { & { 
        width : 84%;
        margin : 20px auto;
        justify-content : center;

        }
    }
`

const BodyWrapper = styled.div`
    padding : 60px 150px;
    display : block;
    width : calc(100% - 300px);
    min-width :1215px;  
    text-align : left;
    background-color : ${colors.background};
    text-align : center;
    @media screen and (max-width: 768px) 
{ & { 
    min-width : 0;
    padding : 70px 20px;
    width : calc(100% - 40px);
    }
}
`
const BodyImgWrapper = styled.div`
    position : relative; 
    width : 100%;
    height : 130px;
    background-image : url(${back});
    background-size : 100% auto;
    background-position-y :300px;
    @media screen and (max-width: 768px) 
{ & { 
    background-position-y :0px;
    }
`

export default Manual2;
