import {react,useEffect,useState,useRef} from "react";
import styled from "styled-components"
import colors from "./colors";
import phone from "../img/TalkingIcon.png";
import { useHistory } from "react-router";
import androidQR from "../img/AndroidQR.png"
import IosQR from "../img/IosQR.png";
import Modal3 from "./Modal3";
const Body4=()=>{
    const History = useHistory();
    const [ModalState, SetModalState] = useState(false);
    return(
        <Wrapper>
            {ModalState?<Modal3 SetModalState={SetModalState}/>:<div/>}
            <WhiteSpan>3일간 무료로 프로그램 성능을 체험해보세요!</WhiteSpan>
            <div>
          <DivWrapper>
            <QRtext>안드로이드</QRtext>
          <QRimg src={androidQR}/>
          </DivWrapper>
          <DivWrapper>
          <QRtext2>1:1 상담</QRtext2>  
          <Phoneimg src={phone}/>
          </DivWrapper>
          <ButtonWrapper>
          <ButtonBox onClick={()=>{History.push("/Join")}}><ButtonBoxSpan>무료 회원가입</ButtonBoxSpan></ButtonBox>
          <ButtonBox2 onClick={()=>{window.open('https://coinangel.co.kr:1004/app_download', '_blank')}}><ButtonBoxSpan>다운로드 링크</ButtonBoxSpan></ButtonBox2>
          </ButtonWrapper>
            </div>
        </Wrapper>
    )
}
const DivWrapper = styled.div`
display : inline-block;
@media screen and (max-width: 768px) 
{
    width : 30%;;
}
`
const WhiteSpan = styled.span`
    color : ${colors.White};
    font-size : 42px;
    font-family : Dream5;
    text-shadow: 3px 2px 5px rgba(0,0,0,0.6);
    display : inline-block; 
    padding : 20px;
    @media screen and (max-width: 768px) 
    {
        font-size : 20px;
    }
`
const ButtonWrapper = styled.div`
width : 260px;
display : inline-block;
vertical-align : middle;
margin-left : 70px;
@media screen and (max-width: 768px) 
{ & { 
    display : block;
    margin : 0 auto;
    }
}
`
const Wrapper = styled.div`
    min-width :1215px;  
    padding : 30px 120px;
    background-color : ${colors.backBlueGray};
    @media screen and (max-width: 768px) 
    { & { 
        padding : 20px 40px;
        min-width :0;  
        }
    }

`
const ButtonBoxSpan = styled.span`
text-align : center;
width : 100%;
display : inline-block;
line-height : 70px;
vertical-align : middle;    
@media screen and (max-width: 768px) 
    { & { 
        line-height : 50px;
    }
    }
`
const ButtonBox = styled.a`
    display : block;
    width : 250px;
    height : 70px; 
    border-radius : 10px;
    background-color : ${colors.SkyBlue};
    box-shadow : 3px 2px 5px ${colors.Black};
    font-family : Dream5;
    font-size : 24px;
    margin : 10px 0;
    cursor:pointer;
    @media screen and (max-width: 768px) 
    { & { 
        display : block;
        margin : 20px auto;
        min-width :0;  
        font-size : 15px;
        width : 200px;
        height : 100%; 
        }
    }
`
const ButtonBox2 = styled.a`
    display : block;
    width : 250px;
    height : 70px; 
    border-radius : 10px;
    background-color : ${colors.textOrenge};
    box-shadow : 3px 2px 5px ${colors.Black};
    font-family : Dream5;
    font-size : 24px;
    margin : 10px 0;
    cursor:pointer;
    @media screen and (max-width: 768px) 
    { & { 
        display : block;
        margin : 20px auto;
        min-width :0;  
        font-size : 15px;
        width : 200px;
        height : 100%; 
        }
    }
`
const QRimg  = styled.img`
    width : 70px;
    height : 70px;
    vertical-align : middle;
    @media screen and (max-width: 768px) 
    { & { 
        display : none;
       }
       &:nth-child(2){
           display : block;
           margin : 0 auto;
       }
    }
`
const QRtext = styled.span`
    display : inline-block;
    height : 70px;
    margin : 0 20px;
    line-height : 70px;
    vertical-align : middle;
    font-size : 24px;
    font-family : Dream5;
    @media screen and (max-width: 768px) 
    {& { 
         display : none;
        }
        &:first-child{
            font-size : 15px;
            line-height : 30px;
            display : block;
            height : 40px;
            margin : 0;
        }
    }
`
const QRtext2 = styled.span`
    display : inline-block;
    height : 70px;
    margin : 0 20px;
    line-height : 70px;
    vertical-align : middle;
    font-size : 24px;
    font-family : Dream5;
    @media screen and (max-width: 768px) 
    { & { 
        width : 100%;
        margin : 0 ;
        line-height : 34px;
        height : 40px;
        padding-left : 0px;
        font-size : 15px;
        }
    }
`
const Phoneimg = styled.img`
width : 70px;
height : 70px;
vertical-align : middle;
@media screen and (max-width: 768px) 
{ & { 
    margin : 10px auto;
    width : 40px;
    height :40px;    
    display : block;
    }
}
`
export default Body4;