import axios from "axios";
import {server,ChangeNickName,ChangePw,ChangePhone} from "./constValue";
 const ChangeInfoFunc = async (email,Info,type)=>{
    let postData; 
    if(type == "nickname"){
        postData ={
            email : email,
            nickname : Info
        };  
        axios.post(server+ChangeNickName,postData).then(res=>{console.log(res)})
    }
    else if(type=="pw"){
        postData ={
            email : email,
            pw : Info
        };  
        axios.post(server+ChangePw,postData).then(res=>{console.log(res)})
    }
    else if(type=="phone"){
        postData ={
            email : email,
            phone : Info
        };  
        axios.post(server+ChangePhone,postData).then(res=>{console.log(res)})
    }
}
export default ChangeInfoFunc;