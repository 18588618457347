import {react,useEffect,useState,useRef} from "react";
import styled from "styled-components"
import colors from "./colors";
import LogoText from "../img/Cion_Angel.png"
import Logo from "../img/LogoCircle.png";
import { useHistory } from "react-router";
const Footer=()=>{
    const History = useHistory();
    return ( 
        <Wrapper>
            <Left>
                <LogoImg src={Logo}/>
                <LogoTextImg src={LogoText}/>
            </Left>
            <Right>
            <LogoUnderText>우리 곁에는 천사가 있습니다</LogoUnderText>
            <Box2 >
                    <Title2 onClick={()=>{History.push("/Manual1")}}>개인정보 처리 방침 </Title2>
                    <Title2 onClick={()=>{History.push("/Manual2")}}> 이용약관</Title2>
                </Box2 >

                <Box>
                    <Title>코인엔젤/소프트웨어 운영 판매사</Title>
                    <ContentSpan>대표이사 : 백랑기  주소 : 서울시 강남구 강남대로 92길31 6층 6245호(역삼동)  등록번호 : 644-11-02110  통신판매업()</ContentSpan>
                    <ContentSpan>이메일 : cada8585@naver.com</ContentSpan>
                    <ContentSpan>계좌번호 : 농협/코인엔젤(백랑기)302-1664-7405-51</ContentSpan>
                </Box>
                <Box>
                    <Title>(주)하이퍼서트/프로그램 개발사</Title>
                    <ContentSpan>대표이사 : 성윤경   주소: 대전 서구 청사로 136  등록번호 : 706-87-00787, info@hypercerts.com</ContentSpan>
                    <ContentSpan>Copyright(c) (주)하이퍼서트</ContentSpan>
                </Box>
            </Right>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    min-width :1215px;  
    padding : 50px 120px;
    background-color : ${colors.backGray};
    @media screen and (max-width: 768px) 
    { & { 
        padding : 30px 40px;
        min-width :0;  
        }
    }
`
const Left = styled.div`
    width : 30%;
    box-sizing : border-box;
    display : inline-block;
    vertical-align : middle;
    @media screen and (max-width: 768px) 
    { & { 
        width : 100%;
    }
    }
    `
const Right = styled.div`
    display : inline-block;
    width : 70%;
    box-sizing : border-box;
    padding-left:50px;
    vertical-align : middle;
    @media screen and (max-width: 768px) 
    { & { 
        width : 100%;
        padding : 0;
    }
`
const LogoImg = styled.img `
    width : 100px;
    height : 100px;
    vertical-align : middle;
    @media screen and (max-width: 768px) 
    { & { 
        width : 60px;
        height :60px;
        padding : 0;
    }
`
const LogoTextImg = styled.img`
    height : 60px;
    position : relative;
    top : 10px;
    margin : 0 15px ;
    vertical-align : middle;
`
const LogoUnderText = styled.span`
    display : inline-block;
    margin : 25px 0;
    font-size : 35px;
    width : 50%;
    text-align : left ;
    color : yellow;
    font-family : designBold;
    @media screen and (max-width: 768px) 
    { & { 
        display : block;
        font-size : 15px;
        text-align : center;
        width :100%;
        margin :10px 0;
    }
`
const Box = styled.div`
margin-bottom : 20px;
color : ${colors.White};
vertical-align : top;

`
const Box2 = styled.div`
margin-bottom : 20px;
color : ${colors.White};
vertical-align : middle;
display : inline-block;
width : 50%;
text-align : left;
@media screen and (max-width: 768px) 
{ & { 
    display : block;
    font-size : 15px;
    text-align : center;
    width :100%;
    margin : 10px 0;
}
`
const Title = styled.span`
    display : block;
    font-size : 20px;
    font-family : Dream4;
    margin  : 10px 0;
    text-align : left;
    @media screen and (max-width: 768px) 
    { & { 
        text-align :center;
        font-size :18px;
    }
`
const Title2 = styled.span`
    display : inline-block;
    font-size : 15px;
    font-family : Dream4;
    margin  : 10px 0;
    width : 40%;
    text-align : left;
    cursor : pointer;
    @media screen and (max-width: 768px) 
    { & { 
        text-align :center;
    }
`
const ContentSpan = styled.span`
    display : block;
    font-size : 17px;
    font-family : Dream3;
    margin  : 5px 0;
    text-align : left;
    @media screen and (max-width: 768px) 
    { & { 
        font-size : 10px;
        text-align : center;
        width : 100%;
    }
`
export default Footer;
